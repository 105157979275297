import { z } from 'zod';

export const coordinatesSchema = z.object({
    x: z.number(),
    y: z.number(),
    z: z.number()
});

export type Coordinates = z.infer<typeof coordinatesSchema>;

export const cameraCoordinatesSchema = z.object({
    position: coordinatesSchema,
    target: coordinatesSchema
});

export type CameraCoordinates = z.infer<typeof cameraCoordinatesSchema>;
