import { z } from 'zod';
import { ClientUserRole } from './client.types';
import { DataLayoutPrefs } from './dataLayout.types';
import { Locale } from './i18n.types';
import { UserNotificationSettingsSchema } from './payloads/user.payload';
import { CalendarZoom } from './calendar.types';
import type { ClientPreferencesSchema } from './payloads/client.payload';

export type LegacyProfile = {
    firstname?: string | null;
    lastname?: string | null;
    role?: string | null;
    phone?: string | null;
    firm?: string | null;
    city?: string | null;
    country?: string | null;
};

export type LegacyAccountProfile = {
    access?: {
        level: 'user';
    };
    origin?: string;
    plugins: string[];
    stripe?: {
        customer: any;
    };
    [key: string]: any;
};
export type LegacyAuth = {
    name?: undefined;
    type: SessionType;
    api_key: string;
    email: string;
    client: string;
    client_uuid: string;
    client_auth: LegacyClientAuth;
};

export type MinimalLegacyAuth = Omit<LegacyAuth, 'client_uuid' | 'client_auth' | 'name'> & {
    client_auth?: LegacyClientAuth;
};

export type LegacyClientAuth = {
    name: string;
    api_key: string;
    email?: string;
    type: SessionType.Client;
};

export enum SessionType {
    Client = 'client',
    User = 'user'
}

export type User = {
    uuid: string;
    email: string;
    passwordReset: Date | null;
    created: Date;
    expires: Date | null;
    deleteRequested: Date | null;
    client: { name: string; uuid: string };
    locale: string;
    hasAccount: boolean;
} & LegacyProfile;

export type UserAndNewPassword = User & {
    newUserPassword?: string;
};

export const userClientRightsSchema = z.object({
    castAdmin: z.boolean().optional(),
    companyAdd: z.boolean().optional(),
    itemAdd: z.boolean().optional(),
    projectAdd: z.boolean().optional(),
    projectAdmin: z.boolean().optional(),
    contactAdd: z.boolean().optional(),
    contactAdmin: z.boolean().optional()
});

export type UserClientRights = z.infer<typeof userClientRightsSchema>;

export type UserSession = LegacyProfile & {
    uuid: string;
    email: string;
    locale: Locale;
    totpEnabled: boolean;
    client: {
        uuid: string;
        name: string;
        apiKey: string;
        rights: UserClientRights;
        role: ClientUserRole;
        preferences: ClientPreferencesSchema | null;
        strictOIDCEnabled: boolean;
        forceTotp: boolean;
        legacy?: boolean;
    };
    workspaces: {
        uuid: string;
        name: string;
        apiKey: string;
        rights: UserClientRights;
        role: ClientUserRole;
        preferences: ClientPreferencesSchema | null;
        strictOIDCEnabled: boolean;
        forceTotp: boolean;
        legacy?: boolean;
    }[];
    isSuperAdmin?: boolean;
    //@todo mark as required when deployed for some time
    connection?:
        | {
              type: 'password';
          }
        | { type: 'oidc'; client: string };
};

export type BaseUserType = LegacyProfile & {
    uuid: string;
    email: string;
    locale?: string;
};

export type UserType = LegacyProfile & {
    uuid: string;
    email: string;
};

export enum UserFileType {
    BACKGROUND = 'background',
    LOGO = 'logo',
    CLIENT_LOGO = 'client_logo',
    CLIENT_BACKGROUND = 'client_background',
    PROFILE = 'profile',
    WATERMARK = 'watermark'
}

export const userFileSchema = z.object({
    uid: z.string(),
    type: z.nativeEnum(UserFileType).nullable(),
    key: z.string().nullable(),
    url: z.string().nullable(),
    urlExpires: z.date().nullable(),
    thumbnailUrl: z.string().nullable(),
    isPrivate: z.boolean(),
    clientName: z.string().optional().nullable(),
    bucketName: z.string(),
    userUuid: z.string().uuid().optional().nullable()
});
export type UserFile = z.infer<typeof userFileSchema>;

export const contentUserSchema = z.object({
    uuid: z.string(),
    email: z.string(),
    firstname: z.string().nullable().optional(),
    lastname: z.string().nullable().optional(),
    role: z.string().nullable().optional(),
    phone: z.string().nullable().optional(),
    picture: z.string().nullable().optional()
});

export type ContentUser = z.infer<typeof contentUserSchema>;

export type UserStorage = { total: number; used: number; available: number };

export type UIPrefs = {
    dataLayouts?: {
        [key: string]: DataLayoutPrefs;
    };
    sortersAndGroupers?: {
        [key: string]: {
            grouper?: string;
            currentGroupsOpened?: string[];
            display?: string;
            groupFilters?: Record<string, string[]>;
        };
    };
    calendarZoom?: CalendarZoom;
    previewOfficeMs?: boolean;
    video_preview?: boolean;
    tooltips?: boolean;
    alwaysHD?: boolean;
    displayProjectSize?: boolean;
    loop?: boolean;
    note_default_draft?: boolean;
    playerBackgroundColor?: string;
    playerSidePanel?: boolean;
    activeProjectFolderTree?: boolean;
};

export type UserPrefs = {
    calendar?: {
        timeSlots?: {
            start: number;
            end: number;
        };
        globalPlanning?: {
            grouper?: string;
            itemColorType?: string;
            grouperFilters?: string[];
        };
    };
    timezone?: string;
    replicate_parent_settings?: boolean;
    rights?: {
        role?: string;
        download?: boolean;
        export?: boolean;
    };
    trigger_bundles?: boolean;
    trigger_metadata?: boolean;
    encode_legacy?: boolean;
    ui: UIPrefs;
    newsletter?: {
        news?: boolean;
        tips?: boolean;
    };
    mail?: {
        notify_digest?: string;
        reset_password?: boolean;
        notify_final_voted_for?: boolean;
        invite_copy?: boolean;
    };
    livenotify_desktop?: boolean;
    livenotify_email?: boolean;
    livenotify?: {
        access_grant?: boolean;
        comment_add?: boolean;
        file_download?: boolean;
        file_export?: boolean;
        file_new?: boolean;
        file_set_final?: boolean;
    };
    tags?: string[];
    labels?: string[];
    recursive?: {
        admittance?: boolean;
        default_presets?: boolean;
        encrypted?: boolean;
        export_mode?: boolean;
        labels?: boolean;
        metadata_admittance?: boolean;
        metadata_cover?: boolean;
        metadata_notes?: boolean;
        metadata_user_livenotify?: boolean;
        public?: boolean;
        public_download?: boolean;
        public_password?: boolean;
        tags?: boolean;
        trigger_bundles?: boolean;
        trigger_metadata?: boolean;
        watermark?: boolean;
    };
};

export type UserState = {
    clientName: string;
    email: string;
    fetchedAccount: boolean;
    fetchingAccount: boolean;
    licenses: UserLicense[];
    storageTotal: number;
    storageUsed: number;
    clientRights: any;
    plugins: string[];
    ipAddress?: string;
    clientUuid?: string;
    isClient?: boolean;
    locale: Locale;
    preferences?: UserPrefs;
    storage?: {
        used?: number;
        available?: number;
        total: number;
    };
    pluginsEnabled: any[];
    uuid: string;
    created?: Date;
    folders?: { uuid: string }[];
    rootFolderUuid?: string;
    password_reset?: string;
    client_uuid?: string;
    client_name?: string;
    storage_expires?: Date | null;
    events?: number;
    projects?: number;
    firstname?: string | null;
    lastname?: string | null;
    phone?: string | null;
    firm?: string | null;
    role?: string | null;
    city?: string | null;
    country?: string | null;
    picture?: string | null;
    tags?: string[];
    labels?: string[];
    requestToken?: string;
    accessToken?: string;
    totp_enabled: boolean;
};

export type UserAppInfos = {
    uuid: string;
    email: string;
    created: Date;
    picture?: string | null;
    passwordReset: Date | null;
    profile: LegacyProfile;
    rootFolderUuid: string;
    clientRole: string;
    client_uuid: string;
    client_name: string;
    clientRights: {
        castAdmin: boolean;
        companyAdd: boolean;
        contactAdd: boolean;
        contactAdmin: boolean;
        itemAdd: boolean;
        projectAdd: boolean;
        projectAdmin: boolean;
    };
    isSuperAdmin: boolean;
    storage: {
        used: number;
        total: number;
    };
    storageExpires: Date | null;
    events: number;
    projects: number;
};

export type UserWithNotificationSettings = LegacyProfile & {
    notifications: UserNotificationSettingsSchema;
    email: string;
    uuid: string;
    locale: Locale;
    teamUuid?: string;
    teamName?: string;
};

export type UserLicense = {
    uuid: string;
    created: Date;
    expires: Date | null;
    owner: LegacyProfile & {
        uuid: string;
        email: string;
    };
    assigned_to: LegacyProfile & {
        uuid: string;
        email: string;
    };
    assigned_by: LegacyProfile & {
        uuid: string;
        email: string;
    };
};

export const DEFAULT_NOTIFICATIONS_SETTINGS: UserNotificationSettingsSchema = {
    digestFrequencyMinutes: 0,
    commentCreated: false,
    fileCreated: false,
    fileFinalized: false,
    fileDownloaded: false,
    fileUnfinalized: false,
    eventCreated: false,
    eventInvitation: false,
    eventUpdated: false,
    memberJoined: false,
    taskCreated: false,
    taskUpdated: false,
    taskAssigned: false
};

export type DecodedSignedToken = {
    userUuid: string;
    userEmail: string;
    jti: string;
    exp: number;
    iat: number;
};
export type DecodedSessionInfos = { decodedToken: DecodedSignedToken; session: UserSession };
