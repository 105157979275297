import { AreaSelectionState } from '@he-novation/config/types/area-selection.types';
import { NoteMetadata } from '@he-novation/config/types/note.types';
import { CameraCoordinates } from '@he-novation/config/types/threeDimensions.types';

import { mapAreaSelectionToMetadata } from '$components/Controls/AreaSelection/AreaSelection.helpers';

export default ({
    activeAudioTrack,
    activeSubtitles,
    tcIn,
    tcOut,
    areaSelection,
    camera,
    height,
    width,
    frame,
    page
}: {
    activeAudioTrack?: number;
    activeSubtitles?: string[];
    tcIn?: string;
    tcOut?: string;
    areaSelection?: AreaSelectionState;
    camera?: CameraCoordinates;
    height?: number;
    width?: number;
    frame?: number;
    page?: number;
}): NoteMetadata => ({
    activeAudioTrack: tcIn ? activeAudioTrack : undefined,
    activeSubtitles: tcIn ? activeSubtitles : undefined,
    tcIn,
    tcOut,
    original: {
        ImageHeight: height || 0,
        ImageWidth: width || 0
    },
    frame,
    page,
    camera,
    ...(areaSelection ? mapAreaSelectionToMetadata(areaSelection) : {})
});
