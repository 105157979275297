import { FEEDBACK } from '@he-novation/config/paths/modals.constants';
import { LegacyProfile } from '@he-novation/config/types/user.types';

import { modalAtom, ModalExtraParams, ModalPayload, modalStore } from '$atoms/modal-atom';
import type modals from '$components/modal/modals';
import { ModalFeedbackPayload } from '$components/modal/modals/ModalFeedback/ModalFeedback';

export const mapUserAndProfileToUser = ({
    email,
    uuid,
    user_uuid,
    firm,
    firstname,
    lastname,
    phone,
    picture,
    role,
    profile,
    vcard,
    ...rest
}: {
    email: string;
    uuid?: string;
    user_uuid?: string;
    firm?: string | null;
    firstname?: string | null;
    lastname?: string | null;
    phone?: string | null;
    picture?: string | null;
    role?: string | null;
    profile?: LegacyProfile;
    vcard?: LegacyProfile;
}) => {
    if (!vcard) vcard = {};
    if (!profile) profile = {};
    return {
        ...rest,
        email,
        firm: vcard.firm || profile.firm || firm,
        firstname: vcard.firstname || profile.firstname || firstname,
        lastname: vcard.lastname || profile.lastname || lastname,
        phone: vcard.phone || profile.phone || phone,
        picture: picture,
        profile: profile || {},
        uuid,
        user_uuid,
        role: vcard.role || profile.role || role
    };
};

export const mapRequestUserAndProfileToUser = ({
    request_user_email,
    request_user_uuid,
    request_user_profile
}: {
    request_user_email: string;
    request_user_uuid: string;
    request_user_profile?: LegacyProfile;
}) =>
    mapUserAndProfileToUser({
        email: request_user_email,
        user_uuid: request_user_uuid,
        profile: request_user_profile
    });

export function openModalFromAtom(
    modal: keyof typeof modals,
    payload?: ModalPayload | null,
    extraParams?: ModalExtraParams | null
) {
    modalStore.set(modalAtom, () => ({
        modal,
        payload,
        extraParams
    }));
}

export function openFeedbackModalFromAtom(
    message: string,
    timeout = 3000,
    payload?: Omit<ModalFeedbackPayload, 'timeout' | 'message'>
) {
    openModalFromAtom(FEEDBACK, {
        message,
        timeout,
        disableOverlay: true,
        alignTop: true,
        ...payload
    });
}
