import { ClientUser } from '@he-novation/config/types/client.types';
import { EventUser, HerawEvent, HerawEventProject } from '@he-novation/config/types/event.types';
import { Item } from '@he-novation/config/types/item.types';
import { Project } from '@he-novation/config/types/project.types';
import { Task } from '@he-novation/config/types/task.types';
import { DragType } from './ScrollCalendar.hooks';

import { BaseClientLabel, ClientEventLabel } from '$types/clientTypes';
import { ContactType } from '$types/contactTypes';

export type ScrollCalendarRowDataType =
    | {
          users: EventUser[];
      }
    | { labels: ClientEventLabel[] }
    | { project: HerawEventProject }
    | { items: Item[] }
    | null;

export type ScrollCalendarRowType = {
    unAssigned?: boolean;
    label: React.ReactNode;
    filterString: string;
    items: ScrollCalendarItemType[];
    data: ScrollCalendarRowDataType;
    filterGrouper?: string[]; // array of labels uuids for grouper filtering
};

export type ScrollCalendarDisplayedEvent = ScrollCalendarItemType & {
    displayedStartDate: Date;
    displayedEndDate: Date;
    level: number;
    style: {
        left: number;
        width: number;
        isEarly: boolean;
        isLate: boolean;
    };
};
export type ScrollCalendarDisplayedRow = {
    data: ScrollCalendarRowDataType;
    unAssigned?: boolean;
    label: React.ReactNode;
    filterString: string;
    displayedEvents: ScrollCalendarDisplayedEvent[];
    maxRowLines: number;
    rowHeight: number;
};

type BaseScrollCalendarItem = HerawEvent & {
    isTask?: boolean;
    task?: Task;
    editionType?: DragType;
    save?: HerawEvent;
};

export type ScrollCalendarItemType =
    | (BaseScrollCalendarItem & {
          recurringIndex?: number;
          isDraft: true;
          draftLevel?: number;
          edits?: { [k: string]: string | number | boolean | null };
          save: HerawEvent;
      })
    | (BaseScrollCalendarItem & {
          recurringIndex?: number;
          isDraft?: false;
          draftLevel?: number;
          edits?: { [k: string]: string | number | boolean | null };
      });

export type TimeSlot = {
    start: number;
    end: number;
};

export type GlobalRowsType = {
    users?: (ClientUser | ContactType)[];
    labels?: BaseClientLabel[];
    items?: Item[];
    projects?: Project[];
};

export enum CalendarItemColorType {
    Project = 'Project',
    Label = 'Label',
    Entity = 'Entity'
}

export enum CalendarFilterEnum {
    Teams = 'Teams',
    Labels = 'Labels',
    Authors = 'Authors'
}

export enum ScrollCalendarGrouper {
    Users = 'users',
    Labels = 'labels',
    Projects = 'projects',
    Items = 'items'
}

export type CalendarPreferences = {
    grouper?: ScrollCalendarGrouper;
    itemColorType?: CalendarItemColorType;
    timeSlots?: TimeSlot;
    grouperFilters?: Record<string, string[]>;
};

export type ScrollCalendarWidths = {
    beforeBusinessHourWidth: number;
    afterBusinessHourWidth: number;
    businessHourWidth: number;
    fullWidth: number;
};
