import type { ZodAny, ZodObject, ZodRawShape, ZodSchema, ZodTypeAny, z } from 'zod';
import type { AuthenticatedRequest, NextFunction } from 'express';

export enum ApiMethod {
    GET = 'GET',
    PATCH = 'PATCH',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}
export type ZodValidationObject = ZodTypeAny | undefined;

type ZodValidation = {
    query?: ZodTypeAny;
    body?: ZodTypeAny;
    params?: ZodTypeAny;
};

type Response = { description: string; schema?: ZodSchema<any>; contentTypes?: string[] };

type Responses = {
    [code: number]: Response;
};
export type RouteDefinition<
    Q extends ZodValidationObject,
    B extends ZodValidationObject,
    P extends ZodValidationObject
> = {
    method: ApiMethod;
    path: string | string[];
    handlerMethod: string;
    zod?: {
        query?: Q;
        body?: B;
        params?: P;
    };
    parseMultipart?: boolean;
    contentType?: string;
    mw?: ((req: Request, res: Response, next: NextFunction) => void)[];
    isPublic?: boolean;
    superAdminOnly?: boolean;
    clientAdminOnly?: boolean;
    passResAndNextToHandler?: boolean;
    publicApi?: boolean | 'deprecated';
    description?: string;
    responses?: Responses;
    group?: string;
    ignoreZod?: boolean; // if public api needs to display a schema but it's validation is handled elsewhere
    ignoreMultipart?: boolean; // if public api needs to display a multipart schema but it is handled elsewhere
    allowNoMfa?: boolean;
};

export type InferZodField<
    R extends RouteDefinition<ZodValidationObject, ZodValidationObject, ZodValidationObject>,
    K extends keyof ZodValidation
> = R['zod'] extends ZodValidation
    ? R['zod'][K] extends ZodTypeAny
        ? z.infer<R['zod'][K]>
        : null
    : null;

export type InferZodPayload<
    R extends RouteDefinition<ZodValidationObject, ZodValidationObject, ZodValidationObject>,
    K extends keyof ZodValidation
> = R['zod'] extends ZodValidation
    ? R['zod'][K] extends ZodTypeAny
        ? z.infer<R['zod'][K]>
        : undefined
    : undefined;

export type ApiResponse<
    R extends RouteDefinition<ZodValidationObject, ZodValidationObject, ZodValidationObject>
> = R['responses'] extends Responses
    ? R['responses'][200] extends Response
        ? R['responses'][200]['schema'] extends ZodSchema<any, z.ZodTypeDef, any>
            ? z.infer<R['responses'][200]['schema']>
            : any
        : any
    : any;

export type HandlerSignature<
    R extends RouteDefinition<ZodValidationObject, ZodValidationObject, ZodValidationObject>
> = (
    req: AuthenticatedRequest<
        InferZodField<R, 'params'>,
        InferZodField<R, 'query'>,
        InferZodField<R, 'body'>
    >,
    res: R['passResAndNextToHandler'] extends true ? Response : undefined,
    next: R['passResAndNextToHandler'] extends true ? NextFunction : undefined
) => Promise<ApiResponse<R>>;
