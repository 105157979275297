const strToQueryParams = (str: string): Record<string, string | null> => {
    const queryParams: Record<string, string | null> = {};

    if (str.indexOf('?') > -1) {
        const params = str.split('?').pop() || '';
        const query = params.split('&');

        query.forEach((q) => {
            const [key, value] = q.split('=');
            if (key) queryParams[key] = value || null;
        });
    }

    return queryParams;
};

export default strToQueryParams;
