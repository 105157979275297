export enum Group {
    Casts = 'Casts',
    Contacts = 'Contacts',
    ContactGroups = 'ContactGroups',
    Events = 'Events',
    Files = 'Files',
    Folders = 'Folders',
    Notes = 'Notes',
    Projects = 'Projects',
    Search = 'Search',
    Subtitles = 'Subtitles',
    Tasks = 'Tasks',
    Teams = 'Teams',
    Users = 'Users',
    Versions = 'Versions'
}
