import { Group } from '../paths/herawApiPathGroups';
import { ApiMethod } from '../types/api.types';
import {
    oaProjectCreateBodySchema,
    oaProjectSchema
} from '../types/open-api/project.open-api.types';
import { projectCreateBody } from '../types/payloads/project.payload';
import { workspaceNameSchema } from '../types/payloads/workspace.payload';
import { projectSchema } from '../types/project.types';

export const PROJECT_POST = {
    method: ApiMethod.POST,
    path: '/workspace/:workspaceName/projects',
    handlerMethod: 'createProject' as 'createProject',
    zod: {
        params: workspaceNameSchema,
        body: projectCreateBody
    },
    responses: {
        200: {
            schema: projectSchema,
            description: 'Created project'
        }
    }
};

export const OA_PROJECT_POST = {
    method: ApiMethod.POST,
    path: '/public/v1/workspace/:workspaceName/projects',
    handlerMethod: 'createProject' as 'createProject',
    publicApi: true,
    description: 'Creates a new project',
    group: Group.Projects,
    zod: {
        params: workspaceNameSchema,
        body: oaProjectCreateBodySchema
    },
    responses: {
        200: {
            schema: oaProjectSchema,
            description: 'Created project'
        }
    }
};

// @deprecated
export const OA_PROJECT_POST_DEPRECATED = {
    method: ApiMethod.POST,
    path: '/public/v1/projects',
    handlerMethod: 'createProjectOADeprecated' as 'createProjectOADeprecated',
    publicApi: 'deprecated' as 'deprecated',
    description: 'Creates a new project',
    group: Group.Projects,
    zod: {
        body: oaProjectCreateBodySchema
    },
    responses: {
        200: {
            schema: oaProjectSchema,
            description: 'Created project'
        }
    }
};
