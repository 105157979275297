import { Asset, assetSchema } from './asset.types';
import { z } from 'zod';
import { Subtitle } from './subtitle.types';
import { FileStatus } from './db/enums';
import { BreadCrumb } from './folder.types';

export type BaseFileType = {
    /**
     * @TJS-format uuid
     */
    uuid: string;
    name: string;
    version: number;
    mimeType?: string | null;
};

export enum FileEnum {
    Audio = 'audio',
    Document = 'document',
    Video = 'video',
    HTML = 'html',
    Image = 'image',
    PDF = 'pdf',
    Office = 'office',
    ThreeDimensionsModel = '3d'
}

const fileSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    created: z.date().nullable(),
    updated: z.date().nullable(),
    version: z.number(),
    metadata: z.any().nullable(),
    site: z.string(),
    tags: z.string().array(),
    status: z.nativeEnum(FileStatus),
    folder: z.object({
        uuid: z.string(),
        name: z.string()
    })
});
export type File = z.infer<typeof fileSchema>;

export type VersionAssetRecap = {
    name: string;
    ffprobeResult: any;
    exifResult: any;
    version: number;
    size: number | null;
    notes: number;
    created: Date;
    expires: Date | null;
    mimeType: string | null;
    thumbnail: string | null;
    thumbnailExpires: Date | null;
    updating?: boolean;
};

export const contentFileSchema = z
    .object({
        folder: z.object({
            uuid: z.string(),
            name: z.string()
        }),
        project: z
            .object({
                uuid: z.string(),
                name: z.string()
            })
            .nullable()
            .optional(),
        assets: z.array(assetSchema),
        user: z.object({
            uuid: z.string(),
            email: z.string(),
            locale: z.string(),
            firstname: z.string().optional().nullable(),
            lastname: z.string().optional().nullable()
        }),
        creator: z.object({
            uuid: z.string(),
            email: z.string(),
            firstname: z.string().optional().nullable(),
            lastname: z.string().optional().nullable()
        }),
        client: z.object({
            uuid: z.string(),
            name: z.string()
        }),
        frameRate: z.number().optional(),
        site: z.string(),
        finals: z.array(
            z.object({
                user: z.object({
                    uuid: z.string(),
                    email: z.string()
                })
            })
        ),
        resolution: z.tuple([z.number(), z.number()]).optional(),
        timecodestart: z.string().optional(),
        metadata: z.any().optional(),
        metadataAle: z.any().optional(),
        duration: z.number().optional(),
        castFiles: z.array(
            z.object({
                uuid: z.string(),
                castUid: z.string(),
                castName: z.string(),
                castFolderUuid: z.string().nullable().optional()
            })
        ),
        notes: z.number(),
        expires: z.date().nullable()
    })
    .merge(fileSchema.omit({ folder: true }));
export type ContentFile = z.infer<typeof contentFileSchema>;

export type FileState = {
    breadcrumb: (BreadCrumb | { name: string })[];
    parent?: string;
    activeAsset?: Asset;
    activeSubtitles: string[];
    isLoaded: boolean;
    comparison: any | null;
    comparisonAsset?: Asset;
    isPlaying?: boolean;
    type?: FileEnum;
    name?: string;
    subtitlesTimeStamp: number;
    error: any | null;
    iframeCapture?: any;
    versionLocked?: boolean;
    timecodestart?: string;
    subtitles: Subtitle[];
    page?: number;
    highlightedVersion?: number;
    activeAudioTrack?: any;
    width?: number;
    height?: number;
    deletedVersions?: number[];
    version?: number;
    source?: string;
    encryptionRequestToken?: string;
    encryptionAccessToken?: string;
    comparisonSubtitles?: Subtitle[];
    clientName?: string;
    project?: {
        uuid: string;
        name: string;
    } | null;
    description?: string | null;
} & ({ uuid?: undefined; assets?: undefined } | ContentFile);

export type FileFull = Omit<ContentFile, 'folder'> & {
    description: string | null;
    folder: { uuid: string; name: string; hasWatermark?: boolean };
};

export type FileVotes = {
    voters: {
        uuid: string;
        email: string;
        created: Date;
        requestUser: { uuid: string; email: string } | null;
    }[];
    reporters: { uuid: string; email: string }[];
};

export type BasicFile = {
    uuid: string;
    name: string;
    created: Date;
    updated: Date | null;
    version: number;
    thumbnail: string | null;
    mimeType: string;
    type: 'basic-file';
    status: FileStatus;
    isLoading: boolean;
    isPlayable: boolean;
    size: number;
};
