import { useDispatch } from 'react-redux';
import strToQueryParams from '@he-novation/config/paths/utils/strToQueryParams';
import { useAtom } from 'jotai';

import {
    modalAtom,
    ModalExtraParams,
    ModalName,
    ModalPayload,
    modalStore
} from '$atoms/modal-atom';
import { setRoute } from '$redux/route/routeActions';
import { queryParamsToStr } from '$redux/route/routeReducer';

export function useModal() {
    const dispatch = useDispatch();

    const [modal, setModal] = useAtom(modalAtom, { store: modalStore });

    const openModal = (
        modal: ModalName,
        payload?: ModalPayload | null,
        extraParams?: ModalExtraParams | null,
        silent = true
    ) => {
        setModal({
            modal,
            payload,
            extraParams
        });

        const queryParams: ModalExtraParams = strToQueryParams(window.location.href);
        queryParams.modal = modal;

        if (extraParams) {
            for (const param in extraParams) {
                queryParams[param] =
                    typeof extraParams[param] === 'object'
                        ? JSON.stringify(extraParams[param])
                        : extraParams[param];
            }
        }
        const route = `${window.location.pathname}${window.location.hash.replace(
            /\?.+/,
            ''
        )}${queryParamsToStr(queryParams)}`;

        if (!silent) dispatch(setRoute(route, silent));
    };

    const closeModal = () => {
        const queryParams = strToQueryParams(window.location.href);
        delete queryParams.modal;

        for (const param in queryParams) {
            if (
                param.includes('modal') ||
                (modal?.extraParams && Object.keys(modal?.extraParams).includes(param))
            ) {
                delete queryParams[param];
            }
        }

        const route = `${window.location.pathname}${queryParamsToStr(queryParams)}`;

        setModal(null);

        dispatch(setRoute(route));
    };

    return {
        openModal,
        closeModal,
        modal: modal?.modal,
        payload: modal?.payload,
        extraParams: modal?.extraParams
    };
}
