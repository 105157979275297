import { FileStatus, TaskStatus } from '@he-novation/config/types/db/enums';
import type { BasicFile } from '@he-novation/config/types/file.types';
import type { BasicFolder } from '@he-novation/config/types/folder.types';
import type { Task } from '@he-novation/config/types/task.types';
import { toRoundDate } from '@he-novation/front-shared/utils/datetime';
import { isSameDay, offsetDays } from '@he-novation/utils/datetime';
import { property } from 'lodash/fp';
import sortBy from 'lodash/sortBy';

import { DataLayoutGroup } from '$components/DataLayout/DataLayout.types';
import { sortByResolution } from '$components/DataLayout/sorterFunctions';
import { Translator } from '$hooks/useTranslate';

const getPropertyFromPropertyStrings = <T>(p: T, propertyStrings: (keyof T)[]) => {
    let name: string;
    for (let i = 0, iLength = propertyStrings.length; i < iLength; i++) {
        name = property(propertyStrings[i])(p);
        if (name) return name;
    }
    return '';
};
export function companyGrouperFactory<T>(t: Translator, propertyString: string, keyPrefix = '') {
    return (items: T[]): DataLayoutGroup<T>[] =>
        items.reduce((acc: DataLayoutGroup<T>[], p) => {
            const property = getPropertyFromPropertyStrings(p, [propertyString as keyof T]);
            const companyName = property || t('contact.Without company');

            const i = acc.findIndex((c) => c.key === keyPrefix + companyName);
            if (i === -1) {
                acc.push({ key: keyPrefix + companyName, header: companyName, items: [p] });
            } else {
                acc[i].items.push(p);
            }
            return acc;
        }, []);
}

export function nameGrouperFactory<T>(t: Translator, ...propertyStrings: (keyof T)[]) {
    return (items: T[]): DataLayoutGroup<T>[] =>
        sortBy(items, (item) =>
            getPropertyFromPropertyStrings(item, propertyStrings).toLowerCase()
        ).reduce(
            (acc: DataLayoutGroup<T>[], p) => {
                const property = getPropertyFromPropertyStrings(p, propertyStrings);

                const ALRegex = /^[a-lA-L].*$/;
                const MZRegex = /^[m-zM-Z].*$/;
                const foundAL = property.match(ALRegex);
                const foundMZ = property.match(MZRegex);

                if (foundAL) {
                    acc[0].items.push(p);
                } else if (foundMZ) {
                    acc[1].items.push(p);
                } else {
                    acc[2].items.push(p);
                }
                return acc;
            },
            [
                { key: 'A-L', header: 'A-L', items: [] },
                { key: 'M-Z', header: 'M-Z', items: [] },
                { key: 'OTHER', header: t('folder.Others'), items: [] }
            ]
        );
}

export function fileTypeGrouperFactory<
    T extends
        | { type: 'file' | 'basic-file' | 'ghost'; mimeType?: string; isBundle?: boolean }
        | { type: 'folder' | 'basic-folder' }
>(t: Translator) {
    return function fileTypeGrouper(items: T[]): DataLayoutGroup<T>[] {
        return items.reduce(
            (acc: DataLayoutGroup<T>[], i) => {
                const mimeType =
                    i.type === 'file' || i.type === 'basic-file' || i.type === 'ghost'
                        ? i.mimeType || ''
                        : '';
                const isBundle = i.type === 'file' ? i.isBundle : false;
                switch (true) {
                    case i.type === 'folder':
                    case i.type === 'basic-folder':
                        acc[0].items.push(i);
                        return acc;
                    case mimeType.startsWith('audio/'):
                        acc[1].items.push(i);
                        return acc;
                    case mimeType.startsWith('video/'):
                    case mimeType === 'application/mxf':
                        acc[3].items.push(i);
                        return acc;
                    case mimeType.startsWith('image/'):
                    case mimeType.startsWith('application/vnd'):
                        acc[2].items.push(i);
                        return acc;
                    case mimeType === 'application/pdf':
                        acc[4].items.push(i);
                        return acc;
                    case isBundle:
                        acc[5].items.push(i);
                        return acc;
                    default:
                        acc[6].items.push(i);
                }

                return acc;
            },
            [
                { key: 'folders', header: t('common.Folder'), items: [] },
                { key: 'audios', header: t('folder.Audio files'), items: [] },
                { key: 'images', header: t('folder.Images'), items: [] },
                { key: 'videos', header: t('folder.Videos'), items: [] },
                { key: 'pdfs', header: t('folder.PDF'), items: [] },
                { key: 'html', header: t('folder.HTML'), items: [] },
                { key: 'others', header: t('folder.Others'), items: [] }
            ]
        );
    };
}

export function dateGrouperFactory<K extends string, T extends Partial<Record<K, Date | null>>>(
    t: Translator,
    key: K
) {
    return function dateGrouper(items: T[]): DataLayoutGroup<T>[] {
        const today = toRoundDate(new Date());
        const yesterday = offsetDays(today, -1);
        const lastWeek = offsetDays(today, -7);
        const lastMonth = offsetDays(today, -30);

        return items
            .reduce(
                (acc: ({ date: Date } & DataLayoutGroup<T>)[], i) => {
                    if (!i[key]) {
                        if (!acc[4]) {
                            acc.push({
                                key: 'noDate',
                                header: t('folder.No date'),
                                items: [],
                                date: new Date()
                            });
                        }
                        acc[4].items.push(i);
                        return acc;
                    }
                    const date = new Date(i[key]);
                    switch (true) {
                        case isSameDay(date, today):
                            acc[0].items.push(i);
                            return acc;
                        case date > yesterday:
                            acc[1].items.push(i);
                            return acc;
                        case date > lastWeek:
                            acc[2].items.push(i);
                            return acc;
                        case date > lastMonth:
                            acc[3].items.push(i);
                            return acc;
                        default: {
                            const y = date.getFullYear();
                            if (today.getFullYear() !== y) {
                                const groupIndex = acc.findIndex((g) => g.key === y.toString());
                                if (groupIndex === -1)
                                    acc.push({
                                        key: y.toString(),
                                        header: y,
                                        items: [i],
                                        date: date
                                    });
                                else acc[groupIndex].items.push(i);
                            } else {
                                const m = date.toLocaleString('default', {
                                    month: 'long',
                                    year: 'numeric'
                                });
                                const groupIndex = acc.findIndex((g) => g.key === m);
                                if (groupIndex === -1)
                                    acc.push({ key: m, header: m, items: [i], date: date });
                                else acc[groupIndex].items.push(i);
                            }
                        }
                    }
                    return acc;
                },
                [
                    { key: 'today', date: today, header: t('common.Today'), items: [] },
                    { key: 'yesterday', date: yesterday, header: t('common.Yesterday'), items: [] },
                    {
                        key: 'last7days',
                        date: lastWeek,
                        header: t('folder.Last {{n}} days', { n: 7 }),
                        items: []
                    },
                    {
                        key: 'last30days',
                        date: lastMonth,
                        header: t('folder.Last {{n}} days', { n: 30 }),
                        items: []
                    }
                ]
            )
            .sort((a, b) => b.date.getTime() - a.date.getTime());
    };
}

export function sizeGrouperFactory<T extends { size?: number } | BasicFolder | BasicFile>(
    t: Translator
) {
    return function sizeGrouper(items: T[]): DataLayoutGroup<T>[] {
        return items.reduce(
            (acc: DataLayoutGroup<T>[], i) => {
                const size = i?.size || 0;
                const kb = Math.floor(size / 1024);
                const mb = Math.floor(kb / 1024);

                switch (true) {
                    case !i.size:
                        acc[5].items.push(i);
                        return acc;
                    case mb < 100:
                        acc[4].items.push(i);
                        return acc;
                    case mb < 1000:
                        acc[3].items.push(i);
                        return acc;
                    case mb < 10000:
                        acc[2].items.push(i);
                        return acc;
                    case mb < 100000:
                        acc[1].items.push(i);
                        return acc;
                    default:
                        acc[0].items.push(i);
                }
                return acc;
            },
            [
                {
                    key: 'over100gb',
                    header: t('common.More than {{n}}', { n: '100Gb' }),
                    items: []
                },
                { key: '10gb-100gb', header: '10Gb - 100Gb', items: [] },
                { key: '1gb-10gb', header: '1Gb - 10Gb', items: [] },
                { key: '100mb-1gb', header: '100Mb - 1Gb', items: [] },
                {
                    key: 'lessThan100mb',
                    header: t('common.Less than {{n}}', { n: '100Mb' }),
                    items: []
                },
                { key: 'others', header: t('common.Others'), items: [] }
            ]
        );
    };
}

export function versionNumberGrouperFactory<
    T extends
        | { type: 'folder' | 'basic-folder' }
        | { type: 'file' | 'basic-file'; version: number }
        | { type: 'ghost'; version: number }
>(t: Translator) {
    return function versionNumberGrouper(items: T[]): DataLayoutGroup<T>[] {
        return items
            .reduce(
                (acc: DataLayoutGroup<T>[], i) => {
                    if (i.type === 'folder' || i.type === 'basic-folder') {
                        const groupIndex = acc.findIndex((g) => g.header === t('common.Folders'));
                        acc[groupIndex].items.push(i);
                        return acc;
                    }
                    const v = (i as { version: number }).version;
                    const groupName = t('folder.{{n}} version(s)', { n: v + 1 });
                    const groupIndex = acc.findIndex((g) => g.header === groupName);
                    if (groupIndex === -1)
                        acc.unshift({
                            key: (v + 1).toString(),
                            header: groupName,
                            items: [i]
                        });
                    else acc[groupIndex].items.push(i);
                    return acc;
                },
                [{ key: 'folders', header: t('common.Folders'), items: [] }]
            )
            .sort((a, b) => parseInt(a.key) - parseInt(b.key));
    };
}

export function statusGrouperFactory<
    T extends
        | { type: 'folder' | 'basic-folder' }
        | { type: 'ghost' }
        | { type: 'file' | 'basic-file'; status: FileStatus }
>(t: Translator) {
    return function statusGrouper(items: T[]): DataLayoutGroup<T>[] {
        return items.reduce(
            (acc: DataLayoutGroup<T>[], i) => {
                if (i.type !== 'file') {
                    acc[5].items.push(i);
                } else {
                    const group = acc.find((g) => g.key === i.status)!;
                    group.items.push(i);
                }
                return acc;
            },
            [
                { key: FileStatus.final, header: t('folder.__FILE_STATUS_FINAL'), items: [] },
                {
                    key: FileStatus.to_validate,
                    header: t('folder.__FILE_STATUS_TO_VALIDATE'),
                    items: []
                },
                {
                    key: FileStatus.in_progress,
                    header: t('folder.__FILE_STATUS_IN_PROGRESS'),
                    items: []
                },
                { key: FileStatus.retake, header: t('folder.__FILE_STATUS_RETAKE'), items: [] },
                { key: FileStatus.default, header: t('common.Others'), items: [] },
                { key: 'folders', header: t('common.Folders'), items: [] }
            ]
        );
    };
}

export function searchGrouperFactory<T extends { type: 'file' | 'folder' | 'comment' }>(
    t: Translator
) {
    return function searchGrouper(items: T[]): DataLayoutGroup<T>[] {
        return items.reduce(
            (acc: DataLayoutGroup<T>[], i) => {
                switch (i.type) {
                    case 'folder':
                        acc[0].items.push(i);
                        return acc;
                    case 'file':
                        acc[1].items.push(i);
                        return acc;
                    case 'comment':
                        acc[2].items.push(i);
                        return acc;
                }
            },
            [
                { key: 'folders', header: t('common.Folders'), items: [] },
                { key: 'files', header: t('common.Files'), items: [] },
                { key: 'comments', header: t('common.Comments'), items: [] }
            ]
        );
    };
}

export function durationGrouperFactory<
    T extends
        | { type: 'file'; duration?: number }
        | { type: 'folder' | 'ghost' | 'basic-file' | 'basic-folder' }
>(t: Translator) {
    return function durationGrouper(items: T[]): DataLayoutGroup<T>[] {
        return items
            .reduce(
                (acc: ({ duration?: number } & DataLayoutGroup<T>)[], item) => {
                    const duration = item.type === 'file' ? item.duration : 0;
                    let group: string;
                    if (item.type !== 'file' || typeof item.duration !== 'number') {
                        group = t('common.Others');
                    } else {
                        if (duration! < 60000) {
                            group = t('common.Less than {{n}}', { n: 1 + 'min' });
                        } else if (duration! < 3600000) {
                            group =
                                t('common.Less than {{n}}', {
                                    n: Math.ceil(duration! / 600000) * 10
                                }) + 'min';
                        } else {
                            group = t('common.Less than {{n}}', {
                                n: Math.ceil(duration! / 3600000) + 'h'
                            });
                        }
                    }

                    const groupIndex = acc.findIndex((g) => g.header === group);
                    if (groupIndex === -1) {
                        acc.push({
                            key: group,
                            header: group,
                            items: [item],
                            duration: duration
                        });
                    } else {
                        acc[groupIndex].items.push(item);
                    }
                    return acc;
                },
                [{ key: 'others', header: t('common.Others'), items: [] }]
            )
            .sort((a, b) => (a.duration || 0) - (b.duration || 0));
    };
}

export function resolutionGrouperFactory<
    T extends
        | { type: 'folder' | 'ghost' | 'basic-file' | 'basic-folder' }
        | { type: 'file'; resolution?: [number, number] }
>(t: Translator) {
    return function resolutionGrouper(items: T[]): DataLayoutGroup<T>[] {
        return items
            .reduce((acc: ({ resolution?: [number, number] } & DataLayoutGroup<T>)[], item) => {
                const group =
                    item.type === 'file' && item.resolution
                        ? `${item.resolution[0]} x ${item.resolution[1]}`
                        : t('common.Others');

                const groupIndex = acc.findIndex((g) => g.key === group);
                if (groupIndex === -1) {
                    acc.push({
                        key: group,
                        header: group,
                        items: [item],
                        resolution: item.type === 'file' ? item.resolution : undefined
                    });
                } else {
                    acc[groupIndex].items.push(item);
                }
                return acc;
            }, [])
            .sort(sortByResolution);
    };
}

export function taskStatusGrouperFactory<T extends Task>(t: Translator) {
    return function statusGrouper(items: T[]): DataLayoutGroup<T>[] {
        return items.reduce(
            (acc: DataLayoutGroup<T>[], i) => {
                const group = acc.find((g) => g.key === i.status)!;
                group.items.push(i);
                return acc;
            },
            [
                {
                    key: TaskStatus.TO_DO,
                    header: t('project.To do'),
                    items: []
                },
                { key: TaskStatus.IN_PROGRESS, header: t('project.In progress'), items: [] },
                {
                    key: TaskStatus.TO_VALIDATE,
                    header: t('project.To validate'),
                    items: []
                },
                { key: TaskStatus.DONE, header: t('project.Done'), items: [] }
            ]
        );
    };
}
