import { Asset } from '../asset.types';

export enum WSNoteEventType {
    NoteAttachment = 'note/attachment'
}

export interface WSNoteEventNoteAttachment {
    type: WSNoteEventType.NoteAttachment;
    asset: Asset;
    note: {
        uuid: string;
    };
    comment: {
        uuid: string;
    };
}
