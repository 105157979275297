import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { homeLink, SETTINGS_USER_PROFILE } from '@he-novation/config/paths/herawFrontUris';
import { PASSWORD_UPDATE_FORM } from '@he-novation/config/paths/modals.constants';
import { ClientPreferences } from '@he-novation/config/types/client.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { Error } from '@he-novation/design-system/components/text/Error/Error';
import { ErrorBoundary } from '@sentry/react';
import debounce from 'lodash/fp/debounce';
import { compose } from 'recompose';
import { Dispatch } from 'redux';

import { ViewError, viewErrorAtom, viewErrorStore } from '$atoms/error-atoms';
import { withHook } from '$components/HOC/withHook';
import { withJotaiAtom } from '$components/HOC/withJotaiAtom';
import combineSelectors from '$helpers/combineSelectors';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';
import { clientPreferencesSelector } from '$redux/client/clientSelectors';
import { loadQueryParams, set } from '$redux/route/routeActions';
import { routeSelector } from '$redux/route/routeSelectors';
import store from '$redux/store';
import {
    preferencesSelector,
    totpEnabledSelector,
    UserInfos,
    userInfosSelector
} from '$redux/user/userSelectors';

const checkPasswordExpiration = debounce(10000, (openModal, clientPreferences, accountInfo) => {
    if (clientPreferences?.password?.expires && clientPreferences?.password?.expires_days) {
        if (!accountInfo.passwordReset) {
            return openModal();
        }

        const passwordExpirationDate = new Date(accountInfo.passwordReset);
        passwordExpirationDate.setTime(
            passwordExpirationDate.getTime() +
                clientPreferences.password.expires_days * 24 * 60 * 60 * 1000
        );
        const now = new Date();
        if (passwordExpirationDate < now) {
            return openModal();
        }
    }
});

type RouterProps = {
    clientPreferences: ClientPreferences;
    configRoute: string;
    loadQueryParams: (routeName: string) => void;
    onUpdate: (previousRoute: string, newRoute: string) => void;
    params: Record<string, string>;
    queryParams: Record<string, string>;
    route: string;
    routes: Record<
        string,
        {
            Component: React.ComponentType<
                {
                    route: {
                        route: string;
                        params: Record<string, string>;
                        queryParams: Record<string, string>;
                    };
                } & unknown
            >;
            ignoreParamsInKey?: boolean;
            preserveQueryParams?: boolean;
        }
    >;
    setRoute: (route: string, silent?: boolean, replace?: boolean) => void;
    setViewError: (error: ViewError) => void;
    userInfos: UserInfos;
    openModal: ReturnType<typeof useModal>['openModal'];
    viewError: ViewError;
    genericErrorMessage: string;
    totpEnabled: boolean;
};

function Router({
    clientPreferences,
    configRoute,
    loadQueryParams,
    onUpdate,
    params,
    queryParams,
    route,
    routes,
    setRoute,
    setViewError,
    userInfos,
    openModal,
    viewError,
    genericErrorMessage,
    totpEnabled
}: RouterProps) {
    let errorBoundary: ErrorBoundary | null = null;

    const onPopState = useCallback(() => {
        store.dispatch(set(window.location.href.replace(window.location.origin, ''), true));
    }, []);

    const [lastConfigRoute, setLastConfigRoute] = useState<string | null>(null);

    useEffect(() => {
        window.addEventListener('popstate', onPopState);
        //facebook redirect
        if (route.startsWith('_=')) {
            setRoute(homeLink(), false, true);
        }

        if (window.location.pathname === '/index') {
            const path = '/' + window.location.hash.replace(/^#\/?/, '');
            setRoute(path, false, true);
        }
        return () => {
            window.removeEventListener('popstate', onPopState);
        };
    }, []);

    useEffect(() => {
        setViewError(null);
        errorBoundary?.resetErrorBoundary();
    }, [route]);

    useEffect(() => {
        document.body.classList.toggle('cross-fade', true);
        setTimeout(() => {
            document.body.classList.toggle('cross-fade', false);
        }, 200);

        if (routes[configRoute]?.preserveQueryParams) {
            loadQueryParams(configRoute);
        }

        if (onUpdate) {
            onUpdate(lastConfigRoute || '', configRoute);
        }
        setLastConfigRoute(configRoute);

        checkPasswordExpiration(
            () => openModal(PASSWORD_UPDATE_FORM, { passwordExpired: true }),
            clientPreferences,
            userInfos
        );
    }, [configRoute]);

    const { t } = useTranslate();

    const mfaError = clientPreferences?.forceTotp && !totpEnabled;
    const _viewError =
        mfaError && route !== SETTINGS_USER_PROFILE ? (
            <>
                <p>
                    {t(
                        'misc.Two-factor authentication has been made mandatory by your workspace administrator. Please enable it in your account settings to continue using HERAW.'
                    )}
                </p>
                <Button
                    tone={ButtonTone.Neutral}
                    onClick={() => {
                        setRoute(SETTINGS_USER_PROFILE);
                    }}
                >
                    {t('settings.Change my settings')}
                </Button>
            </>
        ) : (
            viewError
        );

    if (_viewError) {
        return <Error error={_viewError || genericErrorMessage} />;
    }

    if (configRoute) {
        const Comp = routes[configRoute].Component;
        let key = `${configRoute}`;
        if (!routes[configRoute].ignoreParamsInKey) key += `-${JSON.stringify(params)}`;
        return (
            <ErrorBoundary
                ref={(r) => (errorBoundary = r)}
                fallback={<Error error={genericErrorMessage} />}
            >
                <Comp
                    key={key}
                    route={{
                        route: route,
                        params: params,
                        queryParams: queryParams
                    }}
                />
            </ErrorBoundary>
        );
    }
    return null;
}

export default compose(
    connect(
        combineSelectors(
            preferencesSelector,
            routeSelector,
            clientPreferencesSelector,
            userInfosSelector,
            totpEnabledSelector
        ),
        (dispatch: Dispatch) => ({
            loadQueryParams: (routeName: string) => dispatch(loadQueryParams(routeName)),
            setRoute: (route, silent, replace) => dispatch(set(route, silent, replace))
        })
    ),
    withHook(useModal, 'openModal'),
    withJotaiAtom(viewErrorAtom, 'viewError', viewErrorStore)
)(Router);
