import paths, {
    castFolderContentPaths,
    pathWithParams
} from '@he-novation/config/paths/herawApiPaths';
import {
    Cast,
    CastFolderResponse,
    FileCast,
    FolderCast
} from '@he-novation/config/types/cast.types';
import { CastContentChild } from '@he-novation/config/types/cast.types';
import { CastCreateBody, CastUpdateBody } from '@he-novation/config/types/payloads/cast.payload';
import { mapCastToFrontCast } from '../mappers/cast.mappers';
import { FrontCast } from '../types/cast.front-types';
import { apiFetch } from './apiFetch';

export const asyncCastsFetch = (projectUuid?: string): Promise<Cast[]> =>
    apiFetch(paths.cast.root, {
        method: 'GET',
        query: { projectUuid }
    })
        .catch((e) => {
            console.error(e);
            return [];
        })
        .then((casts) =>
            casts.map((c) => ({
                ...c,
                created: new Date(c.created),
                expires: c.expires && new Date(c.expires)
            }))
        );

export const asyncCastFetch = (castUid: string, projectUuid?: string): Promise<Cast> =>
    apiFetch(paths.cast.specific, {
        method: 'GET',
        params: { castUid },
        query: { projectUuid }
    }).then((c) => ({
        ...c,
        created: new Date(c.created),
        expires: c.expires && new Date(c.expires)
    }));

export const asyncCastFoldersSelect = (castUid: string) =>
    apiFetch(paths.castFolders.root, {
        method: 'GET',
        params: { castUid }
    });

export const asyncCastFolderSelect = (
    castUid: string,
    castFolderUuid?: string
): Promise<CastFolderResponse> =>
    apiFetch(paths.castFolders.specific, {
        method: 'GET',
        params: { castUid, castFolderUuid }
    });
export const asyncPrivateCastSelect = (
    castUid: string,
    castFolderUuid?: string | null,
    projectUuid?: string
): Promise<FrontCast> =>
    apiFetch(paths.castPrivate.specific, {
        method: 'GET',
        params: { castUid, castFolderUuid: castFolderUuid || undefined }
    }).then((r) => mapCastToFrontCast(r, true, projectUuid));

export const asyncPublicCastSelect = (
    castUid: string,
    castFolderUuid?: string | null,
    recursive?: boolean,
    p?: string | null
): Promise<FrontCast> =>
    apiFetch(paths.castPublic.specific, {
        method: 'GET',
        params: { castUid, castFolderUuid: castFolderUuid || undefined },
        query: { p, recursive },
        redirect: false
    }).then((r) => mapCastToFrontCast(r, false));

export const asyncPublicFileUuidFromCastFileUuidFetch = (castUid, castFileUuid, p?: string) =>
    apiFetch(paths.castPublic.file, {
        method: 'GET',
        params: {
            castUid,
            castFileUuid
        },
        query: { p },
        redirect: false
    });

export const asyncCastCreate = (payload: CastCreateBody): Promise<Cast> =>
    apiFetch(paths.cast.single, {
        method: 'POST',
        body: payload
    }).then((r) => ({
        ...r,
        created: new Date(r.created),
        expires: r.expires && new Date(r.expires)
    }));

export const asyncCastEdit = (castUid, payload: CastUpdateBody): Promise<Cast> =>
    apiFetch(paths.cast.specific, {
        method: 'PUT',
        params: { castUid },
        body: payload
    }).then((r) => ({
        ...r,
        created: new Date(r.created),
        expires: r.expires && new Date(r.expires)
    }));

export const asyncCastDelete = (castUid: string) =>
    apiFetch(paths.cast.specific, {
        method: 'DELETE',
        params: { castUid }
    });

export const asyncCastFolderCreate = (
    castUid: string,
    castFolderUuid: string | undefined,
    name: string
) =>
    apiFetch(paths.castFolders.single, {
        method: 'POST',
        params: { castUid },
        body: { castFolderUuid, name }
    });

export const asyncCastFolderUpdate = (castUid: string, castFolderUuid: string, name: string) =>
    apiFetch(paths.castFolders.specific, {
        method: 'PUT',
        params: { castUid, castFolderUuid },
        body: { name }
    });

export const asyncCastFolderDelete = (castUid: string, castFolderUuid: string) =>
    apiFetch(paths.castFolders.specific, {
        method: 'DELETE',
        params: { castUid, castFolderUuid }
    });

export const asyncCastFileCreate = (
    castUid: string,
    castFolderUuid: string | undefined,
    fileUuid: string
) =>
    apiFetch(paths.castFiles.single, {
        method: 'POST',
        params: { castUid },
        body: { castFolderUuid, fileUuid }
    });

export const asyncCastFileDelete = (castUid: string, castFileUuid: string) =>
    apiFetch(paths.castFiles.specific, {
        method: 'DELETE',
        params: { castUid, castFileUuid }
    });

export const asyncFileCastsFetch = (fileUuid: string): Promise<FileCast[]> =>
    apiFetch(pathWithParams(paths.castFiles.casts, { fileUuid }));

export const asyncIncreaseCastViews = (castUid: string) =>
    apiFetch(pathWithParams(paths.castPublic.views, { castUid }), {
        method: 'PUT'
    });

export const asyncCastShare = (
    emails: string[],
    cast: { name: string; uid: string },
    message?: string
) =>
    apiFetch(paths.cast.share, {
        method: 'POST',
        body: { emails, cast, message }
    });

export const asyncFolderCastTriggerCreate = (folderUuid, castUid, castFolderUuid) =>
    apiFetch(pathWithParams(paths.castTrigger.single, { castUid }), {
        method: 'POST',
        body: JSON.stringify({
            folderUuid,
            castFolderUuid
        })
    });
export const asyncFolderCastTriggerDelete = (castUid, folderCastTriggerUid) =>
    apiFetch(pathWithParams(paths.castTrigger.specific, { castUid, folderCastTriggerUid }), {
        method: 'DELETE'
    });

export const asyncCastsFetchFromFolder = (folderUuid): Promise<FolderCast[]> =>
    apiFetch(pathWithParams(paths.cast.fromFolder, { folderUuid }), {
        method: 'GET'
    }).catch((e) => {
        console.error('Failed to fetch casts from folder', e);
        return [];
    });

export const asyncCastFolderContentFetch = (
    castUid: string,
    castFolderUuid?: string
): Promise<CastContentChild<false>[]> =>
    apiFetch(castFolderContentPaths.specific, {
        method: 'GET',
        params: { castUid, castFolderUuid }
    });
