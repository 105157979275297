import { pathWithParams } from '../paths/herawApiPaths';
import type {
    ApiMethod,
    ApiResponse,
    InferZodPayload,
    RouteDefinition,
    ZodValidationObject
} from '../types/api.types';

const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-requested-with': 'XMLHttpRequest'
};

const removeContentTypeHeaders = (headers: Record<string, string>) => {
    delete headers['Content-Type'];
    return headers;
};

export type ApiFetchOptions = {
    url: string;
    method: ApiMethod;
    body?: Record<string, unknown> | Float64Array;
    params?: Record<string, string | number | undefined>;
    query?: Record<string, string | number | undefined | null | string[]>;
    headers?: Record<string, string>;
    rawUrl?: boolean;
    noDefaultHeaders?: boolean;
    signal?: AbortSignal;
    rawResponse?: boolean;
};

export const buildOptions = (options: ApiFetchOptions, removeContentType): RequestInit => {
    const headers = options.noDefaultHeaders
        ? options.headers
        : options.headers
        ? { ...defaultHeaders, ...options.headers }
        : defaultHeaders;
    if (removeContentType) removeContentTypeHeaders(headers || {});

    let body: RequestInit['body'] | undefined;
    if (typeof options.body === 'object') {
        for (const key in options.body) {
            if (options.body[key] instanceof Date)
                options.body[key] = options.body[key].toISOString();
        }
        body =
            options.headers?.['content-type'] === 'application/octet-stream'
                ? (options.body as Float64Array)
                : JSON.stringify(options.body);
    } else {
        body = options.body;
    }
    return {
        method: options.method,
        headers,
        mode: 'cors',
        credentials: 'include',
        signal: options.signal,
        body
    };
};

export const fetchCallback = (options: ApiFetchOptions) => async (r) => {
    if (options.rawResponse) return r;
    if (r.status > 299) {
        const response = await r.json();
        throw response;
    } else {
        return r.status !== 204 ? r.text().then((r) => (r ? JSON.parse(r) : r)) : null;
    }
};

export const apiFetch = (options: ApiFetchOptions, removeContentType?: boolean) => {
    let url = options.url;
    if (options.query || options.params) {
        url = pathWithParams(url, options.params || {}, options.query || {});
    }
    return fetch(
        url.startsWith('http') || options.rawUrl
            ? url
            : `${process.env.API_URL}/${url.replace(/^\//, '')}`,
        buildOptions(options, removeContentType)
    ).then(fetchCallback(options));
};

type UndefinableToOptional<T> = {
    [K in keyof T as undefined extends T[K] ? K : never]?: Exclude<T[K], undefined>;
} & { [K in keyof T as undefined extends T[K] ? never : K]: T[K] };

export function buildApiCall<
    Q extends ZodValidationObject,
    B extends ZodValidationObject,
    P extends ZodValidationObject,
    R extends RouteDefinition<Q, B, P>
>({ path, method }: R) {
    return ({
        options,
        ...payloadOptions
    }: UndefinableToOptional<{
        query: InferZodPayload<R, 'query'>;
        body: InferZodPayload<R, 'body'>;
        params: InferZodPayload<R, 'params'>;
    }> & {
        options?: Omit<ApiFetchOptions, 'url' | 'method' | 'query' | 'params' | 'body'>;
    }): Promise<ApiResponse<R>> => {
        return apiFetch({
            url:
                typeof path === 'string'
                    ? path
                    : //  select longest path in array to ensure maximal routeParams are handled
                      path.reduce((a, b) => (a.length > b.length ? a : b)),
            method,
            ...payloadOptions,
            ...options
        });
    };
}
