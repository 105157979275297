import { z } from 'zod';
import { ROLES } from '../../constants/projects.constants';
import { sanitizedString } from '@he-novation/utils/zod.utils';

export const oaProjectSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    role: z.string().optional(),
    status: z.string().optional(),
    folderUuid: z.string(),
    company: z
        .object({
            uuid: z.string(),
            name: z.string(),
            color: z.string().nullable().optional()
        })
        .nullable()
        .optional(),
    created: z.date(),
    updated: z.date().nullable(),
    endDate: z.date().nullable().optional(),
    startDate: z.date().nullable().optional(),
    isFavorite: z.boolean().optional(),
    size: z.number().optional(),
    files: z.number().optional(),
    client: z.object({
        uuid: z.string(),
        name: z.string()
    }),
    taskCounts: z.object({
        toDo: z.number(),
        done: z.number(),
        inProgress: z.number(),
        toValidate: z.number()
    }),
    hasPlanning: z.boolean(),
    hasStats: z.boolean(),
    hasCast: z.boolean(),
    team: z.object({
        uuid: z.string(),
        name: z.string()
    }),
    user: z.object({
        uuid: z.string(),
        email: z.string(),
        firstname: z.string().optional(),
        lastname: z.string().optional(),
        firm: z.string().optional(),
        job: z.string().optional(),
        locale: z.string()
    }),
    defaultCastStyle: z
        .object({
            textColor: z.string().nullable().optional(),
            backgroundColor: z.string().nullable().optional(),
            userFileUid: z.string().nullable().optional(),
            logoUserFileUid: z.string().nullable().optional(),
            backgroundFileUid: z.string().nullable().optional()
        })
        .nullable()
        .optional()
});

export const oaProjectCreateBodySchema = z.object({
    name: sanitizedString(),
    startDate: z.coerce.date().optional().nullable(),
    endDate: z.coerce.date().optional().nullable(),
    companyName: z.string().optional().nullable()
});

export const oaProjectUpdateBodySchema = oaProjectCreateBodySchema.partial();

export const oaProjectUuidSchema = z.object({
    projectUuid: z.string().uuid()
});
export const oaProjectUpdateStatusBodySchema = z.object({
    status: z.string()
});

export const oaWorkspaceNameAndProjectUuidSchema = z.object({
    workspaceName: z.string().min(3),
    projectUuid: z.string().uuid()
});

export const oaProjectUuidAndUserUuidSchema = z.object({
    projectUuid: z.string().uuid(),
    userUuid: z.string().uuid()
});

export const oaProjectUpdateMemberBody = z.object({
    teamUuid: z.string().uuid().optional(),
    role: z.nativeEnum(ROLES).optional(),
    export: z.boolean().optional(),
    download: z.boolean().optional()
});

export type OAProject = z.infer<typeof oaProjectSchema>;
export type OAProjectCreateBody = z.infer<typeof oaProjectCreateBodySchema>;
export type OAProjectUpdateBody = z.infer<typeof oaProjectUpdateBodySchema>;
export type OAProjectUuidSchema = z.infer<typeof oaProjectUuidSchema>;
export type OAClientNameAndProjectUuidSchema = z.infer<typeof oaWorkspaceNameAndProjectUuidSchema>;
export type OAProjectUpdateStatusBody = z.infer<typeof oaProjectUpdateStatusBodySchema>;
export type OAProjectUuidAndUserUuidSchema = z.infer<typeof oaProjectUuidAndUserUuidSchema>;
export type OAProjectUpdateMemberBody = z.infer<typeof oaProjectUpdateMemberBody>;

export const oaTeamSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    color: z.string().nullable().optional(),
    status: z.string().optional(),
    castTeamAccess: z.boolean().optional(),
    ownTeam: z.boolean().optional()
});

export const oaTeamMemberSchema = z.object({
    uuid: z.string(),
    email: z.string(),
    firstname: z.string().nullable().optional(),
    lastname: z.string().nullable().optional(),
    phone: z.string().nullable().optional(),
    firm: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    country: z.string().nullable().optional(),
    role: z.nativeEnum(ROLES),
    canExport: z.boolean().optional(),
    canDownload: z.boolean().optional()
});

export const oaTeamWithMembersSchema = oaTeamSchema.extend({
    members: z.array(oaTeamMemberSchema)
});

export type OATeamMember = z.infer<typeof oaTeamMemberSchema>;
export type OATeam = z.infer<typeof oaTeamSchema>;
export type OATeamWithMembers = z.infer<typeof oaTeamWithMembersSchema>;
