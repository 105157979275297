import styles from './FormNote.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEAM_ADMIN, TEAM_CAST } from '@he-novation/config/constants/projects.constants';
import { TeamMember, TeamWithMembers } from '@he-novation/config/types/team.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import {
    FormField,
    ModularForm
} from '@he-novation/design-system/components/form/FormField/FormField';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import cn from 'classnames';
import { useAtomValue } from 'jotai';
import { useAtom } from 'jotai/index';

import { areaSelectionAtom } from '$atoms/file-atoms/area-selection-atom';
import { activeSubtitlesAtom } from '$atoms/file-atoms/subtitle-atoms';
import { cameraCoordinatesAtom } from '$atoms/file-atoms/threeDimensions-atom';
import { creatingTaskAtom } from '$atoms/note-atoms';
import { AttachmentButton } from '$components/SidePanel/SidePanelFile/SidePanelNotes/AttachmentButton/AttachmentButton';
import { FormNoteChips } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/FormNoteChips';
import { FormNoteControls } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/FormNoteControls';
import { FormNoteLogin } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/FormNoteLogin';
import { NoteTagButton } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/NoteLabelButton/NoteTagButton';
import { TaskDatePicker } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/TaskDatePicker/TaskDatePicker';
import { TeamMemberPicker } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/TeamMemberPicker/TeamMemberPicker';
import { useGenerateThumbnail } from '$hooks/useGenerateThumbnail';
import { useCreateNotesAndComments } from '$hooks/useNotes';
import { useOnKeyDown } from '$hooks/useOnKeyDown';
import { useProjectTeams } from '$hooks/useProjectTeams';
import { useTranslate } from '$hooks/useTranslate';
import {
    activeAssetSelector,
    audioTracksAndActiveAudioTrackSelector,
    fileHighlightedVersionSelector,
    fileNameUuidAndVersionSelector,
    fileProjectUuidSelector,
    fileTypeSelector,
    heightAndWidthSelector,
    pageAndPageNumberSelector
} from '$redux/content/file/fileSelectors';
import { folderProjectSelector } from '$redux/content/folder/folderSelectors';
import mapPayloadToMetadata from '$redux/content/note/map/mapPayloadToMetadata';
import { castFileUuidRouteSelector, castUidRouteSelector } from '$redux/route/routeSelectors';
import { toggleKeyboardListeners } from '$redux/ui/uiActions';
import { userUuidSelector } from '$redux/user/userSelectors';
import { useCastPassword } from '$views/cast/castHelpers';

type FormNoteProps = {
    toggled: boolean;
    setToggled: (toggled: boolean) => void;
};

export function FormNote({ toggled, setToggled }: FormNoteProps) {
    const formId = 'form-note';
    const castFileUuid = useSelector(castFileUuidRouteSelector);
    const castUid = useSelector(castUidRouteSelector);

    const dispatch = useDispatch();
    const [creatingTask, setCreatingTask] = useAtom(creatingTaskAtom);
    const { fileType } = useSelector(fileTypeSelector);
    const { fileUuid, fileVersion } = useSelector(fileNameUuidAndVersionSelector);
    const { fileHighlightedVersion } = useSelector(fileHighlightedVersionSelector);
    const project = useSelector(folderProjectSelector);
    const [isLoading, setIsLoading] = useState(false);
    const { activeAudioTrack } = useSelector(audioTracksAndActiveAudioTrackSelector);
    const activeSubtitles = useAtomValue(activeSubtitlesAtom);
    const areaSelection = useAtomValue(areaSelectionAtom);
    const cameraCoordinates = useAtomValue(cameraCoordinatesAtom);
    const { page } = useSelector(pageAndPageNumberSelector);
    const { width, height } = useSelector(heightAndWidthSelector);
    const activeAsset = useSelector(activeAssetSelector);
    const { userUuid } = useSelector(userUuidSelector);
    const { fileProjectUuid } = useSelector(fileProjectUuidSelector);

    const [selectedTeam, setSelectedTeam] = useState<TeamWithMembers | undefined>();
    const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
    const [estimatedEndDate, setEstimatedEndDate] = useState<Date | undefined>();
    const [attachments, setAttachments] = useState<File[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [postedAs, setPostedAs] = useState<string | null>(null);
    const submitButton = useRef<HTMLButtonElement>(null);

    const generateThumbnail = useGenerateThumbnail();

    const { noteCreate } = useCreateNotesAndComments();

    const { t } = useTranslate();

    useOnKeyDown(
        'enter',
        (e) => {
            if (e.shiftKey) {
                e.preventDefault();
                submitButton.current?.click();
                dispatch(toggleKeyboardListeners(true));
            }
        },
        [],
        {
            useKeyboardListenersSelector: false
        }
    );

    const [pageWidth, pageHeight] = useMemo(() => {
        if (page !== undefined && (!width || !height)) {
            const pageSize = activeAsset?.metadata?.pageSizes?.[page!];
            return [pageSize?.width, pageSize?.height];
        }
        return [width, height];
    }, [page, width, height]);

    const { accessibleTeams, teams } = useProjectTeams();

    useEffect(() => {
        if (!selectedTeam) {
            if (!!castFileUuid && teams.length > 0) {
                setSelectedTeam(teams.find((t) => t.name === TEAM_CAST));
            } else if (accessibleTeams.length > 0) {
                setSelectedTeam(accessibleTeams[0]);
            }
        }
    }, [accessibleTeams, teams, castFileUuid, selectedTeam]);

    const onFocus = () => {
        dispatch(toggleKeyboardListeners(false));
    };
    const onBlur = () => {
        dispatch(toggleKeyboardListeners(true));
    };

    const formRef = useRef<HTMLFormElement>();

    const { password } = useCastPassword(castUid);

    useEffect(() => {
        setCreatingTask(!!castFileUuid && !!fileProjectUuid);
    }, [castFileUuid, fileProjectUuid]);

    useEffect(() => {
        return () => {
            setCreatingTask(false);
        };
    }, []);

    return (
        <>
            {!toggled && (
                <Button
                    onClick={() => setToggled(true)}
                    className={cn(styles.toggle, styles.formNote)}
                >
                    {t('player.Add note')}
                </Button>
            )}
            {toggled && (
                <div className={cn(styles.form, styles.formNote, formStyles.light)}>
                    {!userUuid && <FormNoteLogin setPostedAs={setPostedAs} />}

                    <ModularForm
                        id={formId}
                        ref={formRef}
                        onSubmit={async (
                            e: SyntheticEvent<HTMLFormElement, SubmitEvent>,
                            data: {
                                content: string;
                                tcIn?: string;
                                tcOut?: string;
                                type: 'sequence' | 'areaselection' | 'global';
                                teamUuid?: string;
                                recaptcha?: string;
                            }
                        ) => {
                            e.preventDefault();
                            if (!userUuid && !postedAs) {
                                return;
                            }

                            const draft = e.nativeEvent.submitter?.id === 'submit-note-draft';
                            setIsLoading(true);
                            const metadata = mapPayloadToMetadata({
                                activeAudioTrack,
                                activeSubtitles,
                                tcIn: data.tcIn,
                                tcOut: data.tcOut,
                                areaSelection,
                                camera: cameraCoordinates || undefined,
                                height: pageHeight,
                                width: pageWidth,
                                page
                            });

                            const imageData = metadata.rectangle
                                ? await generateThumbnail({
                                      ...metadata,
                                      rectangle: metadata.rectangle!
                                  })
                                : undefined;

                            await noteCreate(
                                {
                                    content: data.content,
                                    draft,
                                    fileVersion:
                                        fileHighlightedVersion === undefined
                                            ? fileVersion
                                            : fileHighlightedVersion,
                                    isTask: creatingTask,
                                    metadata,
                                    imageData,
                                    notify: false,
                                    teamUuid: data.teamUuid,
                                    estimatedEndDate,
                                    assignees: teamMembers?.map((member) => member.uuid),
                                    type: data.type,
                                    tags: castFileUuid ? undefined : tags,
                                    fileUuid,
                                    castFileUuid,
                                    recaptcha: data.recaptcha,
                                    postedAs: postedAs || undefined,
                                    attachments
                                },
                                !!castFileUuid,
                                password
                            ).catch((e) => console.error(e));

                            setIsLoading(false);
                            setToggled(false);
                            setAttachments([]);
                            setTeamMembers([]);
                            setTags([]);
                            (document.getElementById(formId) as HTMLFormElement).reset();
                            setCreatingTask(false);
                        }}
                    >
                        {castFileUuid && (
                            <FormField
                                formId={formId}
                                type="recaptcha"
                                name="recaptcha"
                                siteKey={process.env.RECAPTCHA_FRONT_KEY}
                                action={'note'}
                            />
                        )}
                        {project && (
                            <FormField
                                formId={formId}
                                name="noteType"
                                type="radio-toggle"
                                style="tabs"
                                value={creatingTask ? 'task' : 'note'}
                                onChange={(e) => setCreatingTask(e.target.value === 'task')}
                                options={[
                                    {
                                        label: (
                                            <div className={styles.mode}>
                                                <Icon icon="check_encircled" stroke="white" />{' '}
                                                {t('project.Task')}
                                            </div>
                                        ),
                                        value: 'task'
                                    },
                                    {
                                        label: (
                                            <div className={styles.mode}>
                                                <Icon icon="pencil" stroke="white" />{' '}
                                                {t('player.Note')}
                                            </div>
                                        ),
                                        value: 'note'
                                    }
                                ]}
                            />
                        )}
                        {project && selectedTeam && (
                            <FormField
                                formId={formId}
                                name="teamUuid"
                                type="react-select"
                                className={styles.teamSelect}
                                value={selectedTeam.uuid}
                                validation={{
                                    required: true
                                }}
                                options={accessibleTeams
                                    .filter((t) => t.name !== TEAM_CAST)
                                    .map((team) => ({
                                        value: team.uuid,
                                        label: (
                                            <span
                                                className={styles.team}
                                                style={{ color: team.color || undefined }}
                                            >
                                                <Icon
                                                    icon={[
                                                        {
                                                            name: 'users',
                                                            stroke: 'none',
                                                            fill: team.color!
                                                        }
                                                    ]}
                                                />
                                                {team.name}
                                            </span>
                                        )
                                    }))}
                                onChange={(_e, value) => {
                                    const newTeam = accessibleTeams.find((t) => t.uuid === value);
                                    if (newTeam) {
                                        setSelectedTeam(newTeam);
                                    }
                                }}
                            />
                        )}
                        <FormNoteControls formId={formId} fileType={fileType} />

                        <FormField
                            formId={formId}
                            name="content"
                            type="textarea"
                            validation={{
                                required: true,
                                validateOnBlur: false
                            }}
                            autoFocus
                            onFocus={onFocus}
                            onBlur={onBlur}
                        />

                        <FormNoteChips
                            teamMembers={teamMembers}
                            setTeamMembers={setTeamMembers}
                            estimatedEndDate={estimatedEndDate}
                            setEstimatedEndDate={setEstimatedEndDate}
                            attachments={attachments}
                            setAttachments={setAttachments}
                            tags={tags}
                            setTags={setTags}
                        />

                        <footer className={styles.footer}>
                            <div className={styles.options}>
                                <AttachmentButton
                                    attachments={attachments}
                                    setAttachments={setAttachments}
                                />
                                {creatingTask && selectedTeam && (
                                    <TeamMemberPicker
                                        team={selectedTeam}
                                        teamMembers={teamMembers}
                                        pickMember={(member) =>
                                            setTeamMembers([...teamMembers, member])
                                        }
                                    />
                                )}

                                {creatingTask && (
                                    <TaskDatePicker
                                        date={estimatedEndDate}
                                        pickDate={setEstimatedEndDate}
                                    />
                                )}
                                {!castFileUuid && <NoteTagButton tags={tags} setTags={setTags} />}
                            </div>

                            <div className={styles.buttons}>
                                <Button
                                    tone={ButtonTone.Secondary}
                                    type={'button'}
                                    onClick={() => setToggled(false)}
                                >
                                    {t('common.Cancel')}
                                </Button>

                                <div className={styles.submitOrDraft}>
                                    <Button
                                        tone={ButtonTone.Primary}
                                        isLoading={isLoading}
                                        className={styles.submit}
                                        ref={submitButton}
                                        disabled={isLoading || (!userUuid && !postedAs)}
                                    >
                                        {t('common.Publish')}
                                    </Button>

                                    <MultiActionButton
                                        className={styles.orDraft}
                                        contentClassName={styles.submitOrMenu}
                                        tone={ButtonTone.Primary}
                                        type={'button'}
                                        disabled={isLoading || (!userUuid && !postedAs)}
                                        actions={[
                                            {
                                                id: 'submit-note-draft',
                                                form: formId,
                                                type: 'submit',
                                                children: t('player.Save as draft')
                                            }
                                        ]}
                                        direction={[DirectionX.RightInner, DirectionY.Top]}
                                        icon={'chevron'}
                                    />
                                </div>
                            </div>
                        </footer>
                    </ModularForm>
                </div>
            )}
        </>
    );
}
