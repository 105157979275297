import { CalendarZoom } from '@he-novation/config/types/calendar.types';
import { ProjectsStateCurrentProject } from '@he-novation/config/types/project.types';
import { getAllDaysBetweenDates, getDateMinusAndPlusXDays } from '@he-novation/utils/datetime';
import { atom } from 'jotai';

import { FilterName, FilterValueId } from '$components/Filters/FilterCheckboxes';
import {
    CalendarFilterEnum,
    CalendarItemColorType,
    GlobalRowsType,
    ScrollCalendarGrouper,
    ScrollCalendarItemType
} from '$components/ScrollCalendar/ScrollCalendar.types';

export const calendarZoomAtom = atom<CalendarZoom>(CalendarZoom.Weeks);
export const calendarGrouperAtom = atom<ScrollCalendarGrouper>(ScrollCalendarGrouper.Users);
export const calendarActiveFiltersAtom = atom<Record<FilterName, FilterValueId[]>>({
    [CalendarFilterEnum.Teams]: [],
    [CalendarFilterEnum.Labels]: [],
    [CalendarFilterEnum.Authors]: []
});
export const calendarActiveGrouperFilters = atom<string[]>([]);
export const calendarTasksAtom = atom<ScrollCalendarItemType[]>([]);

export const calendarItemsAtom = atom<ScrollCalendarItemType[]>([]);
export const calendarItemColorTypeAtom = atom<CalendarItemColorType>(CalendarItemColorType.Label);
export const calendarPreviewItemsAtom = atom<ScrollCalendarItemType[] | null>(null);
export const calendarDateRangeAtom = atom<[Date, Date]>([
    getDateMinusAndPlusXDays(new Date(), 30).minusXDays,
    getDateMinusAndPlusXDays(new Date(), 30).plusXDays
]);
export const calendarProjectAtom = atom<ProjectsStateCurrentProject | undefined>(undefined);
export const calendarGlobalRowsAtom = atom<GlobalRowsType | undefined>(undefined);
export const calendarActiveCenteredDateAtom = atom<Date>(new Date());
export const calendarSearchTermAtom = atom<string>('');

// make a derived atom for daysInterval
export const calendarDaysIntervalAtom = atom((get) => {
    const dateRange = get(calendarDateRangeAtom);
    return getAllDaysBetweenDates(dateRange[0], dateRange[1]);
});
