import { ApiMethod } from '../types/api.types';
import {
    bulkDeleteFoldersBody,
    folderBasicParam,
    folderSpecific,
    folderTeamsBody,
    folderUpdateBody,
    optionalPendingSchema,
    workspaceNameAndFolderUuidSchema,
    workspaceNameAndOptionalFolderUuidSchema
} from '../types/payloads/folder.payload';
import { workspaceNameSchema } from '../types/payloads/workspace.payload';

export const FOLDER_PATCH = {
    method: ApiMethod.PATCH,
    path: '/workspace/:workspaceName/folder/:folderUuid',
    handlerMethod: 'updateFolder' as 'updateFolder',
    zod: {
        params: workspaceNameAndFolderUuidSchema,
        body: folderUpdateBody
    }
};

export const FOLDER_BULK_DELETE = {
    method: ApiMethod.POST,
    path: '/workspace/:workspaceName/folders-delete',
    handlerMethod: 'bulkDeleteFolders' as 'bulkDeleteFolders',
    zod: {
        params: workspaceNameSchema,
        body: bulkDeleteFoldersBody
    }
};

export const FOLDER_CONTENT_GET = {
    method: ApiMethod.GET,
    path: [
        '/workspace/:workspaceName/folder-content/:folderUuid?',
        '/workspace/:workspaceName/folder-content'
    ],
    handlerMethod: 'getContent' as 'getContent',
    zod: {
        params: workspaceNameAndOptionalFolderUuidSchema,
        query: folderBasicParam
    }
};

export const FOLDER_MEMBERS_GET = {
    method: ApiMethod.GET,
    path: '/workspace/:workspaceName/folder-members/:folderUuid',
    handlerMethod: 'getMembers' as 'getMembers',
    zod: {
        params: workspaceNameAndFolderUuidSchema,
        query: optionalPendingSchema
    }
};

export const FOLDER_PATH_GET = {
    method: ApiMethod.GET,
    path: '/workspace/:workspaceName/folder/:folderUuid/path',
    handlerMethod: 'getPath' as 'getPath',
    zod: {
        params: workspaceNameAndFolderUuidSchema
    }
};

export const FOLDER_TEAMS_GET = {
    method: ApiMethod.GET,
    path: '/folder/:folderUuid/teams',
    handlerMethod: 'getTeams' as 'getTeams',
    zod: {
        params: folderSpecific
    }
};

export const FOLDER_TEAMS_PUT = {
    method: ApiMethod.PUT,
    path: '/folder/:folderUuid/teams',
    handlerMethod: 'setTeams' as 'setTeams',
    zod: {
        params: folderSpecific,
        body: folderTeamsBody
    }
};
