import styles from './SidePanelFolder.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RECURSIVE_SETTINGS } from '@he-novation/config/paths/modals.constants';
import { FolderRole } from '@he-novation/config/types/folder.types';
import {
    FolderNotificationBody,
    FolderUpdateBody
} from '@he-novation/config/types/payloads/folder.payload';
import { acl, ROLE_MANAGER, ROLE_OWNER } from '@he-novation/config/utils/acl';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import { Tabs } from '@he-novation/design-system/components/widgets/Tabs/Tabs';
import {
    asyncFolderUpdate,
    asyncFolderUpdateNotificationSettings,
    fetchFolderSize
} from '@he-novation/front-shared/async/folder.async';
import cn from 'classnames';
import { useAtomValue } from 'jotai';
import { workspaceNameAtom } from '../../../atoms/workspace-atoms';

import { SidePanelFolderInfo } from '$components/SidePanel/SidePanelFolderSettings/SidePanelFolderInfo/SidePanelFolderInfo';
import { SidePanelFolderSettings } from '$components/SidePanel/SidePanelFolderSettings/SidePanelFolderSettings/SidePanelFolderSettings';
import { useModal } from '$hooks/useModal';
import { PubSubEvent, useSubscribe } from '$hooks/useSubscribe';
import { useTranslate } from '$hooks/useTranslate';
import { folderSelector } from '$redux/content/folder/folderSelectors';
import { updateUserPreferences } from '$redux/user/userActions';
import { preferencesSelector } from '$redux/user/userSelectors';

export type FormValues = {
    [field: string]: any;
};

export function SidePanelFolder() {
    const dispatch = useDispatch();

    const { openModal } = useModal();

    const folder = useSelector(folderSelector);
    const { preferences } = useSelector(preferencesSelector);

    const [folderSize, setFolderSize] = useState<number>();
    const [labels, setLabels] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const workspaceName = useAtomValue(workspaceNameAtom);

    useEffect(() => {
        if (folder) {
            fetchFolderSize(folder.uuid).then(({ size }) => {
                setFolderSize(size);
            });
            setLabels(folder.labels);
            setTags(folder.tags);
        }
    }, [folder]);

    useSubscribe(
        PubSubEvent.FileUpload,
        () => {
            if (folder) {
                fetchFolderSize(folder.uuid).then(({ size }) => {
                    setFolderSize(size);
                });
            }
        },
        [folder]
    );

    const onInfoChange = useCallback(
        async ({ tags, labels, description }) => {
            if (!folder) return;

            if (tags) {
                setTags(tags);
            }

            if (labels) {
                setLabels(labels);
            }

            if (acl.folders.modify(folder.role)) {
                await asyncFolderUpdate(workspaceName, folder.uuid, {
                    tags,
                    labels,
                    metadata: { notes: description }
                } as FolderUpdateBody);
            }

            const userTags = preferences.tags || [];
            const userLabels = preferences.labels || [];

            dispatch(
                updateUserPreferences({
                    tags: tags ? [...new Set([...userTags, ...tags])] : userTags,
                    labels: labels ? [...new Set([...userLabels, ...labels])] : userLabels
                })
            );
        },
        [folder]
    );

    const onSettingsChange = useCallback(
        async (settings: FormValues) => {
            if (!folder) return;

            if (acl.folders.modify(folder.role)) {
                await asyncFolderUpdate(
                    workspaceName,
                    folder.uuid,
                    dataToSettings(settings, folder.triggers)
                );
            }

            await asyncFolderUpdateNotificationSettings(
                folder.uuid,
                dataToLiveNotify(settings, folder.role)
            );
        },
        [folder]
    );

    const onRecursiveButtonClick = useCallback(
        (settings: FormValues) => {
            if (!folder) return;

            openModal(
                RECURSIVE_SETTINGS,
                {
                    uuid: folder.uuid,
                    settings: dataToSettings(settings, folder.triggers, tags, labels),
                    shared: folder.role !== ROLE_OWNER,
                    clientName: folder.client.name
                },
                null,
                true
            );
        },
        [folder]
    );

    const { t } = useTranslate();

    return (
        <div
            id="c-side-panel-folder-settings"
            className={cn(styles.folderSidePanel, formStyles.dark)}
        >
            {folder && (
                <Tabs
                    className={styles.tabs}
                    tabs={[
                        {
                            id: 'side-panel-tab-folder-info',
                            label: t('folder.Information'),
                            icon: 'info'
                        },
                        {
                            id: 'side-panel-tab-folder-settings',
                            label: t('common.Settings'),
                            icon: 'cog'
                        }
                    ]}
                >
                    {folderSize !== undefined ? (
                        <SidePanelFolderInfo
                            folder={folder}
                            folderSize={folderSize}
                            onChange={onInfoChange}
                        />
                    ) : (
                        <Loader />
                    )}
                    <SidePanelFolderSettings
                        folder={folder}
                        onChange={onSettingsChange}
                        onRecursiveButtonClick={onRecursiveButtonClick}
                    />
                </Tabs>
            )}
        </div>
    );
}

function dataToNotify(data: FormValues) {
    if (!data['folder-notify-email'] && !data['folder-notify-desktop']) {
        return false;
    }
    if (data['folder-notify-email'] && data['folder-notify-desktop']) {
        return 'all';
    }
    if (data['folder-notify-email']) return 'email';
    return 'desktop';
}

function dataToLiveNotify(data: FormValues, role: FolderRole): FolderNotificationBody {
    const notify = dataToNotify(data);
    return data.livenotify
        ? {
              access_grant: [ROLE_MANAGER, ROLE_OWNER].includes(role)
                  ? data['folder-notify-invite'] && notify
                  : false,
              comment_add: data['folder-notify-comment'] && notify,
              file_download: data['folder-notify-download'] && notify,
              file_export: data['folder-notify-export'] && notify,
              file_new: data['folder-notify-new'] && notify,
              file_set_final: data['folder-notify-final'] && notify
          }
        : {};
}

function dataToSettings(
    data: FormValues,
    triggers: string[],
    tags?: string[],
    labels?: string[]
): FolderUpdateBody {
    const _triggers = triggers.filter((trigger) => !/metadata|bundles/.test(trigger));
    if (data['trigger-metadata']) _triggers.push('metadata');
    if (data['trigger-bundles']) _triggers.push('bundles');

    const payload: FolderUpdateBody = {
        admittance: data.admittance,
        default_presets: data.bypass,
        export_mode: data.export ? data.export_mode : 'never',
        encrypted: data.encrypted,
        public: data.public,
        tags: tags?.filter((t) => t),
        labels: labels?.filter((l) => l),
        public_download: data['folder-option-download-public'],
        public_password: data['folder-settings-form-public-password'] || '',
        triggers: _triggers,
        watermark: data.watermark
    };

    payload.metadata = {
        admittance: data.admittance && {
            role: data['admittance-role'],
            download: data['admittance-download'],
            export: data['admittance-export']
        },
        notes: data.description
    };

    return payload;
}
