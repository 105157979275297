import { useCallback } from 'react';
import { CONFIRM } from '@he-novation/config/paths/modals.constants';
import { fileCopy, fileMove } from '@he-novation/front-shared/async/file.async';
import { asyncFolderCopyTree, folderMove } from '@he-novation/front-shared/async/folder.async';
import {
    FrontFolderContentFile,
    FrontFolderContentFileStatus,
    FrontFolderContentFileTranscodingCopying
} from '@he-novation/front-shared/types/file.front-types';
import {
    FrontFolderChild,
    FrontFolderContentFolder,
    FrontFolderContentGhost
} from '@he-novation/front-shared/types/folder.front-types';
import update from 'immutability-helper';
import { useAtomValue, useSetAtom } from 'jotai';
import { cloneDeep } from 'lodash/fp';

import {
    FolderClipboard,
    folderClipboardAtom,
    FolderClipboardTarget,
    folderContentAtom,
    folderContentWithGhostsAtom,
    folderGhostsAtom,
    foldersStore,
    folderUuidAtom
} from '$atoms/folder-atoms';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';
import { openFeedbackModalFromAtom } from '$redux/helpers';

export function useFolderContent() {
    const { t } = useTranslate();

    const setFolderClipboard = useSetAtom(folderClipboardAtom);
    const folderContent = useAtomValue(folderContentWithGhostsAtom, {
        store: foldersStore
    });
    const setFolderContent = useSetAtom(folderContentAtom, {
        store: foldersStore
    });

    const currentFolderUuid = useAtomValue(folderUuidAtom, { store: foldersStore });

    const { openModal, closeModal } = useModal();

    const updateFolderContentItem = useCallback((itemUuid: string, operation: any) => {
        setFolderContent((folderContent) => {
            if (!folderContent) return folderContent;
            const itemIndex = folderContent.findIndex((c) => c.uuid === itemUuid);
            if (itemIndex === -1) return folderContent;

            return update(folderContent, {
                [itemIndex]: operation
            });
        });
    }, []);

    const deleteFolderContentItem = useCallback((itemUuid: string) => {
        setFolderContent((folderContent) => {
            if (!folderContent) return folderContent;
            return folderContent.filter((f) => itemUuid !== f.uuid);
        });
    }, []);

    const doPaste = useCallback(
        async ({ data, type, projectUuid }: FolderClipboard, target: FolderClipboardTarget) => {
            if (currentFolderUuid && currentFolderUuid === target.folderUuid) {
                setFolderClipboard(null);
                setFolderContent((folderContent) =>
                    (folderContent ?? []).concat(
                        data
                            .filter((i) => i?.type === 'file')
                            .map((item) => ({
                                ...item,
                                created: new Date(item.created),
                                updated: new Date(item.updated),
                                notes: projectUuid !== target.projectUuid ? 0 : item.notes,
                                processingStatus:
                                    type === 'cut'
                                        ? item.processingStatus
                                        : ({
                                              status: FrontFolderContentFileStatus.COPYING
                                          } as FrontFolderContentFileTranscodingCopying)
                            }))
                    )
                );
            }

            let fileExists: boolean;

            const { foldersToCopy, filesToCopy } = data.reduce(
                (acc, f) => {
                    if (f?.type === 'folder') {
                        acc.foldersToCopy.push(f);
                    } else if (f?.type === 'file') {
                        acc.filesToCopy.push(f);
                    }
                    return acc;
                },
                {
                    foldersToCopy: [] as FrontFolderContentFolder[],
                    filesToCopy: [] as FrontFolderContentFile[]
                }
            );

            switch (type) {
                case 'tree':
                    await doCopyTree(target.folderUuid, foldersToCopy);
                    return;
                case 'copy':
                    fileExists = await doCopy(
                        target.folderUuid,
                        filesToCopy,
                        updateFolderContentItem,
                        deleteFolderContentItem
                    );
                    break;
                case 'cut':
                    fileExists = await doMove(target.folderUuid, filesToCopy, foldersToCopy);
                    break;
            }

            if (fileExists) {
                openFeedbackModalFromAtom(
                    t('folder.A file with the same name already exists in this folder')
                );
            }
        },
        [currentFolderUuid, updateFolderContentItem, deleteFolderContentItem]
    );

    const pasteIntoFolder = useCallback(
        async (clipboard: FolderClipboard | undefined | null, target: FolderClipboardTarget) => {
            if (!clipboard) return;

            const data = cloneDeep(clipboard.data);

            if (clipboard.type === 'cut') {
                const containsAtLeastOneFolderOrOneFileWithNotes = !!data.find(
                    (i) => i && (i.type === 'folder' || (i.type === 'file' && i.notes > 0))
                );
                if (
                    clipboard.projectUuid !== target.projectUuid &&
                    containsAtLeastOneFolderOrOneFileWithNotes
                ) {
                    openModal(CONFIRM, {
                        title: t('common.Warning'),
                        message: t(
                            'folder.You are about to change the context of folders and/or files. If you do, the associated notes will be deleted.'
                        ),
                        displayLoader: true,
                        onSubmit: async () => {
                            await doPaste(clipboard, target);
                            closeModal();
                        },
                        closeModal
                    });
                    return;
                }
            }

            await doPaste(clipboard, target);
        },
        [doPaste]
    );

    const dropIntoFolder = useCallback(
        async (
            content: (FrontFolderChild | undefined)[],
            target: FolderClipboardTarget,
            originProjectUuid?: string
        ) => {
            return pasteIntoFolder(
                {
                    data: content.filter((c) => !!c && (c.type === 'file' || c.type === 'folder')),
                    type: 'cut',
                    projectUuid: originProjectUuid
                },
                target
            );
        },
        [pasteIntoFolder]
    );

    return {
        folderContent,
        setFolderContent,
        deleteFolderContentItem,
        pasteIntoFolder,
        dropIntoFolder,
        updateFolderContentItem
    };
}

export function folderContentGhostCreate(ghost: FrontFolderContentGhost) {
    foldersStore.set(folderGhostsAtom, (folderGhosts) => [...folderGhosts, ghost]);
}

export function folderContentGhostDelete(ghostUuid: string) {
    foldersStore.set(folderGhostsAtom, (folderGhosts) =>
        folderGhosts.filter((g) => ghostUuid !== g.uuid)
    );
}

async function doCopy(
    folderUuid: string,
    filesToCopy: FrontFolderContentFile[],
    updateFolderContentItem: (itemUuid: string, operation: any) => void,
    deleteFolderContentItem: (itemUuid: string) => void
) {
    // split filesToCopy in batches of 5 files
    let fileExists = false;
    const fileBatches: FrontFolderContentFile[][] = [];
    for (const file of filesToCopy) {
        if (fileBatches.length === 0 || fileBatches[fileBatches.length - 1].length === 5) {
            fileBatches.push([]);
        }
        fileBatches[fileBatches.length - 1].push(file);
    }
    for (const batch of fileBatches) {
        const r = await Promise.all(
            batch.map(async (f) => {
                try {
                    return await fileCopy(f.uuid.replace(/-copy$/, ''), f.version, folderUuid);
                } catch (e) {
                    return { ...f, error: e?.error?.message };
                }
            })
        );
        batch.forEach((item, i) => {
            if (!r[i].error) {
                updateFolderContentItem(item.uuid, {
                    uuid: {
                        $set: r[i].uuid
                    }
                });
            } else {
                if (r[i].error === 'ERR_FILE_ALREADY_EXISTS') fileExists = true;
                deleteFolderContentItem(item.uuid);
            }
        });
    }

    return fileExists;
}

async function doMove(
    folderUuid: string,
    filesToCopy: FrontFolderContentFile[],
    foldersToCopy: FrontFolderContentFolder[]
) {
    let fileExists = false;
    await Promise.all(
        filesToCopy.map(async (f) => {
            try {
                await fileMove(f.uuid, folderUuid);
            } catch (e) {
                fileExists = true;
                if (e?.error?.message === 'ERR_FILE_ALREADY_EXISTS') fileExists = true;
                return { ...f, error: e?.error?.message };
            }
        })
    );
    await Promise.all(foldersToCopy.map((f) => folderMove(f.uuid, folderUuid)));

    return fileExists;
}

async function doCopyTree(folderUuid: string, foldersToCopy: FrontFolderContentFolder[]) {
    await Promise.all(
        foldersToCopy.map((f) => {
            return asyncFolderCopyTree(f.uuid, folderUuid, {
                renameIfExists: true
            });
        })
    );
}
