import {
    FOLDER_BULK_DELETE,
    FOLDER_CONTENT_GET,
    FOLDER_MEMBERS_GET,
    FOLDER_PATCH,
    FOLDER_PATH_GET,
    FOLDER_TEAMS_GET,
    FOLDER_TEAMS_PUT
} from '@he-novation/config/apis/folder-api';
import {
    folderPaths,
    pathWithParams,
    publicApiV1Paths
} from '@he-novation/config/paths/herawApiPaths';
import type { BasicFile } from '@he-novation/config/types/file.types';
import {
    type BasicFolder,
    BreadCrumb,
    FolderContent,
    FolderMember,
    type FolderRole,
    FolderSizeData,
    FullFolder
} from '@he-novation/config/types/folder.types';
import {
    FolderCopyTreeBody,
    FolderCreateBody,
    FolderInviteBody,
    FolderModifyAccessBody,
    FolderNotificationBody,
    FolderSpecific,
    FolderUpdateBody,
    type WorkspaceNameAndFolderUuidSchema,
    type WorkspaceNameAndOptionalFolderUuidSchema
} from '@he-novation/config/types/payloads/folder.payload';
import type { WorkspaceNameSchema } from '@he-novation/config/types/payloads/workspace.payload';
import { FolderTeam } from '@he-novation/config/types/team.types';
import { buildApiCall } from '../../config/apis/api-fetch';
import { apiFolderContentToFrontFolderContent } from '../mappers/folder.mappers';
import { FrontFolderContent } from '../types/folder.front-types';
import { apiFetch } from './apiFetch';

export const createFolder = (
    workspaceName: string,
    {
        folderUuid,
        name,
        copyParentProperties,
        teamUuids
    }: {
        folderUuid: string;
        name: string;
        copyParentProperties?: boolean;
        teamUuids?: string[];
    }
): Promise<FullFolder> =>
    apiFetch(folderPaths.root, {
        method: 'POST',
        params: {
            workspaceName
        } satisfies WorkspaceNameAndOptionalFolderUuidSchema,
        body: {
            name,
            parentFolderUuid: folderUuid,
            copyParentProperties,
            copyParentMembers: true,
            copyParentMembersPending: true,
            teamUuids
        } satisfies FolderCreateBody
    });

export const fetchFolder = (
    workspaceName: string,
    {
        uuid,
        password
    }: {
        uuid?: string;
        password?: string;
    },
    options?: {
        signal: AbortSignal;
    }
): Promise<FullFolder> =>
    apiFetch(uuid ? folderPaths.specific : folderPaths.root, {
        method: 'GET',
        params: {
            workspaceName,
            folderUuid: uuid
        } satisfies WorkspaceNameAndOptionalFolderUuidSchema,
        query: {
            p: password
        },
        ...options
    });

export const fetchFolderContent = (
    workspaceName: string,
    folderUuid?: string,
    options?: { signal: AbortSignal; basic?: boolean }
): Promise<FrontFolderContent> =>
    buildApiCall(FOLDER_CONTENT_GET)({
        params: { workspaceName, folderUuid },
        query: { basic: options?.basic },
        options
    }).then(apiFolderContentToFrontFolderContent);

export function fetchFolderBasicContent(
    workspaceName: string,
    uuid?: string,
    options?: { signal: AbortSignal }
): Promise<(BasicFile | BasicFolder)[]> {
    return buildApiCall(FOLDER_CONTENT_GET)({
        params: { workspaceName, folderUuid: uuid },
        query: { basic: true },
        options
    });
}

export const fetchSharedRoot = (
    workspaceName: string,
    options?: { signal: AbortSignal }
): Promise<FrontFolderContent> =>
    apiFetch(folderPaths.shared, {
        method: 'GET',
        params: { workspaceName } satisfies WorkspaceNameSchema,
        ...options
    });

export const asyncFolderUpdate = (
    workspaceName: string,
    folderUuid: string,
    body: FolderUpdateBody
) =>
    buildApiCall(FOLDER_PATCH)({
        params: { workspaceName, folderUuid },
        body
    });

export const asyncFolderFetchNotificationSettings = (folderUuid: string) =>
    apiFetch(folderPaths.notifications, {
        params: { folderUuid } satisfies FolderSpecific
    });

export const asyncFolderUpdateNotificationSettings = (
    folderUuid: string,
    body: FolderNotificationBody
) =>
    apiFetch(folderPaths.notifications, {
        method: 'PATCH',
        params: { folderUuid } satisfies FolderSpecific,
        body
    });

export const asyncFolderMembersFetch = (
    workspaceName: string,
    folderUuid: string,
    pending?: boolean
): Promise<FolderMember[]> =>
    buildApiCall(FOLDER_MEMBERS_GET)({
        params: { workspaceName, folderUuid },
        query: { pending }
    }).then((r) =>
        r.map((m) => ({
            ...m,
            download: m.download,
            export: m.export,
            created: new Date(m.created)
        }))
    );

export const fetchFolderSize = (
    folderUuid: string,
    {
        publicSize,
        password,
        flat
    }: {
        publicSize?: boolean;
        password?: string;
        flat?: boolean;
    } = {}
): Promise<FolderSizeData> =>
    apiFetch(
        pathWithParams(
            folderPaths.size,
            { folderUuid },
            { publicSize: publicSize ? 1 : undefined, password, flat: flat ? 1 : undefined }
        ),
        {
            method: 'GET'
        }
    );

export const fetchFolderPlugins = (
    { uuid }: { uuid: string },
    options?: { signal?: AbortSignal }
) =>
    apiFetch(
        pathWithParams(folderPaths.plugins, { folderUuid: uuid } satisfies FolderSpecific),
        options
    );

export const grantAccess = (
    folderUuid: string,
    members: {
        uuid: string;
        role: FolderRole;
        canDownload: boolean;
        canExport: boolean;
    }[],
    message?: string | null
) =>
    apiFetch(folderPaths.invite, {
        params: { folderUuid },
        method: 'POST',
        body: {
            members,
            message
        } satisfies FolderInviteBody
    });

export const revokeAccess = (folderUuid: string, userUuid?: string) =>
    apiFetch(folderPaths.membersSpecific, {
        params: {
            folderUuid,
            userUuid
        },
        method: 'DELETE'
    });

export const leaveSharedFolder = (folderUuid: string) =>
    apiFetch(folderPaths.leave, {
        method: 'DELETE',
        params: {
            folderUuid
        }
    });

export const asyncFolderTeamsFetch = (folderUuid: string) =>
    buildApiCall(FOLDER_TEAMS_GET)({
        params: { folderUuid }
    });

export const fetchFolderPath = (workspaceName: string, folderUuid: string): Promise<BreadCrumb[]> =>
    buildApiCall(FOLDER_PATH_GET)({
        params: { workspaceName, folderUuid }
    });

export const asyncSetFolderTeams = (folderUuid: string, teamUuids: string[]) =>
    buildApiCall(FOLDER_TEAMS_PUT)({
        params: { folderUuid },
        body: { teamUuids }
    });

export const modifyAccess = (
    folderUuid: string,
    userUuid: string,
    { role, canDownload }: FolderModifyAccessBody
) =>
    apiFetch(pathWithParams(folderPaths.membersSpecific, { folderUuid, userUuid }), {
        method: 'PATCH',
        body: {
            role,
            canDownload
        }
    });

export const folderRecursiveSettingsSet = ({
    public_download,
    public_password,
    export_mode,
    default_presets,
    uuid,
    tags,
    labels,
    ...folder
}) => {
    return apiFetch(pathWithParams(folderPaths.recursive, { folderUuid: uuid }), {
        method: 'PUT',
        body: JSON.stringify({
            folder: {
                ...folder,
                tags: tags ? (Array.isArray(tags) ? tags : tags.split(',').filter((v) => v)) : [],
                labels: labels
                    ? Array.isArray(labels)
                        ? labels
                        : labels.split(',').filter((v) => v)
                    : [],
                default_presets,
                public_download,
                public_password,
                export_mode
            }
        })
    });
};

export const asyncFolderCopyTree = (
    sourceFolderUuid: string,
    targetFolderUuid: string,
    { name, renameIfExists }: { name?: string; renameIfExists?: boolean } = {}
) =>
    apiFetch(folderPaths.copyTree, {
        method: 'POST',
        body: {
            sourceFolderUuid,
            targetFolderUuid,
            name,
            renameIfExists
        } satisfies FolderCopyTreeBody
    });

export const folderMove = (sourceUuid: string, destinationUuid: string) =>
    apiFetch(
        pathWithParams(folderPaths.move, {
            sourceFolderUuid: sourceUuid,
            targetFolderUuid: destinationUuid
        }),
        {
            method: 'PUT'
        }
    );

export const asyncFolderBulkDelete = (workspaceName: string, parentUuid: string, uuids: string[]) =>
    buildApiCall(FOLDER_BULK_DELETE)({
        params: {
            workspaceName
        },
        body: {
            parentUuid,
            uuids
        }
    });

export const folderTrashFetch = () => apiFetch(folderPaths.trash, { method: 'GET' });

export const asyncFolderRestore = (folderUuid: string) =>
    apiFetch(folderPaths.restore, {
        method: 'POST',
        params: { folderUuid } satisfies FolderSpecific
    });

export const asyncFolderSharedWith = (userUuid: string) =>
    apiFetch(pathWithParams(folderPaths.sharedWith, { userUuid }), {
        method: 'GET'
    });

export const asyncOpenApiFolderFetch = (
    apiKey: string,
    workspaceName: string,
    folderUuid: string
) =>
    apiFetch(publicApiV1Paths.folder.specific, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + apiKey
        },
        params: { workspaceName, folderUuid }
    });

export const asyncOpenApiFolderContentFetch = (
    apiKey: string,
    workspaceName: string,
    folderUuid: string
): Promise<FolderContent> =>
    apiFetch(publicApiV1Paths.folderContent.specific, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + apiKey
        },
        params: { workspaceName, folderUuid }
    });
