import { ROLES } from '../../constants/projects.constants';
import { Asset } from '../asset.types';
import { FolderRole } from '../folder.types';
import { Task } from '../task.types';
import { TeamWithMembers } from '../team.types';

export enum WSProjectEventType {
    TaskCreate = 'task/create',
    TaskUpdate = 'task/update',
    AttachmentUpload = 'task/attachment/upload'
}

export type WSProjectEventUpdateMember = {
    uuid: string;
    role: FolderRole;
    download: boolean;
    teamUuid?: string;
    projectRole?: ROLES;
};

export type WSProjectEventDeleteMember = {
    uuid: string;
};

export type WSProjectEventCreateTeam = {
    team: TeamWithMembers;
};

export type WSProjectEventUpdateTeam = {
    team: TeamWithMembers;
};

export type WSProjectEventDeleteTeam = {
    uuid: string;
};

export interface WSProjectTaskCreate {
    type: WSProjectEventType.TaskCreate;
    task: Task;
}
export interface WSProjectTaskUpdate {
    type: WSProjectEventType.TaskUpdate;
    task: Task;
    orderChangesByUuid?: Record<string, { orderingDelta?: number; status?: string }>;
}

export interface WSProjectTaskAttachmentUpload {
    type: WSProjectEventType.AttachmentUpload;
    taskUuid: string;
    attachment: Asset;
}
