import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CAST_FILE,
    castFileLink,
    FILE,
    playerLink
} from '@he-novation/config/paths/herawFrontUris';
import { CastContentFile, CastFileState } from '@he-novation/config/types/cast.types';
import { fetchFolderContent } from '@he-novation/front-shared/async/folder.async';
import { apiFolderContentFileToFrontFolderContentFile } from '@he-novation/front-shared/mappers/file.mappers';
import { FrontFolderContentFile } from '@he-novation/front-shared/types/file.front-types';
import { useSetAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';
import { workspaceNameAtom } from '../../atoms/workspace-atoms';

import { initialPlaylistAtom, playlistAtom } from '$atoms/file-atoms/playlist-atom';
import { foldersStore, folderUuidAtom } from '$atoms/folder-atoms';
import { Playlist } from '$components/Playlist/Playlist';
import { stateSelector } from '$redux/content/file/fileSelectors';
import { set } from '$redux/route/routeActions';
import { routeSelector } from '$redux/route/routeSelectors';

export function PlaylistWrapper() {
    const { configRoute } = useSelector(routeSelector);

    if (configRoute === CAST_FILE) return <CastFilePlaylist />;
    if (configRoute === FILE) return <FilePlaylist />;
}

export function FilePlaylist() {
    const dispatch = useDispatch();
    const folderUuid = useAtomValue(folderUuidAtom, { store: foldersStore }) ?? undefined;
    const setPlaylist = useSetAtom(playlistAtom);
    const workspaceName = useAtomValue(workspaceNameAtom);

    useEffect(() => {
        return () => {
            setPlaylist(initialPlaylistAtom);
        };
    }, []);
    return (
        <Playlist
            folderUuid={folderUuid}
            fetchContent={() =>
                fetchFolderContent(workspaceName, folderUuid).then(
                    (folderContent) =>
                        folderContent.filter(
                            ({ type }) => type === 'file'
                        ) as FrontFolderContentFile[]
                )
            }
            openItemInPlayer={(file) => dispatch(set(playerLink(file.uuid, file.version)))}
        />
    );
}

export function CastFilePlaylist() {
    const dispatch = useDispatch();
    const { cast } = useSelector(stateSelector) as unknown as CastFileState;

    if (!cast) return null;

    return (
        <Playlist
            folderUuid={cast.uid}
            fetchContent={() => {
                const contentFiles = cast.content.filter((c) => c.type === 'file');
                const playlistFiles = contentFiles.map((contentFile) =>
                    apiFolderContentFileToFrontFolderContentFile({ ...contentFile, assets: [] })
                );
                return Promise.resolve(playlistFiles);
            }}
            openItemInPlayer={(file: FrontFolderContentFile) => {
                const castFile = cast.content.find(
                    (castFile) => castFile.uuid === file.uuid
                ) as CastContentFile;
                dispatch(set(castFileLink(cast.uid, castFile.castFileUuid, file.version)));
            }}
        />
    );
}
