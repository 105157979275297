import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { useAtom } from 'jotai';

import { isFullscreenAtom, showFullscreenControlsAtom } from '$atoms/fullscreen-atom';
import { isSmallScreenSelector } from '$redux/config/configSelectors';

const MAIN_CONTENT_ELEMENT_ID = 'main-content';

export function useFullscreen() {
    const [isFullscreen, setIsFullscreen] = useAtom(isFullscreenAtom);

    const toggleFullscreen = async () => {
        if (
            document.fullscreenElement &&
            document.fullscreenElement === document.getElementById(MAIN_CONTENT_ELEMENT_ID)
        ) {
            setIsFullscreen(false);
        } else {
            setIsFullscreen(true);
        }
    };

    return {
        isFullscreen,
        toggleFullscreen
    };
}

export function useFullscreenEffect() {
    const [isFullscreen, setIsFullScreen] = useAtom(isFullscreenAtom);
    const [showControls, setShowControls] = useAtom(showFullscreenControlsAtom);

    const [triggeredByOrientationChange, setTriggeredByOrientationChange] =
        useState<boolean>(false);

    const { isSmallScreen } = useSelector(isSmallScreenSelector);

    const hideTimeout = isSmallScreen ? 2000 : 5000;
    const hideTimeoutId = useRef<TimeoutId | null>(null);

    const onOrientationChange = useCallback(
        async (e: Event) => {
            const orientation = e.target as ScreenOrientation;

            switch (orientation.type) {
                case 'landscape-primary':
                case 'landscape-secondary':
                    if (!isFullscreen) {
                        setIsFullScreen(true);
                        setTriggeredByOrientationChange(true);
                    }
                    break;
                case 'portrait-primary':
                case 'portrait-secondary':
                    if (isFullscreen && triggeredByOrientationChange) {
                        setIsFullScreen(false);
                        setTriggeredByOrientationChange(false);
                    }
                    break;
            }
        },
        [isFullscreen, triggeredByOrientationChange]
    );

    const onMouseMove = useCallback(() => {
        if (hideTimeoutId.current) {
            clearTimeout(hideTimeoutId.current);
            hideTimeoutId.current = null;
        }

        setShowControls(true);
        hideTimeoutId.current = setTimeout(() => setShowControls(false), hideTimeout);
    }, [hideTimeoutId.current]);

    const enterFullscreen = useCallback(async () => {
        const target = document.getElementById(MAIN_CONTENT_ELEMENT_ID);
        if (!target) return;

        if (document.fullscreenElement !== target) {
            try {
                await target.requestFullscreen();

                target.onfullscreenchange = (e: Event) => {
                    if (!document.fullscreenElement) {
                        setIsFullScreen(false);
                    }
                };
            } catch (e) {
                console.error('Fullscreen API call failed', e);
            }

            document.addEventListener('mousemove', onMouseMove);

            if (isSmallScreen) {
                setShowControls(false);
            } else {
                hideTimeoutId.current = setTimeout(() => setShowControls(false), hideTimeout);
            }
        }
    }, []);

    const exitFullscreen = useCallback(async () => {
        if (document.fullscreenElement) {
            try {
                await document.exitFullscreen();
            } catch (e) {
                console.error('Fullscreen API call failed', e);
            }

            document.removeEventListener('mousemove', onMouseMove);
        } else {
            document.removeEventListener('mousemove', onMouseMove);
        }
    }, []);

    useEffect(() => {
        if (isFullscreen) {
            enterFullscreen();
        } else if (document.fullscreenElement) {
            exitFullscreen();
        }
    }, [isFullscreen]);

    useEffect(() => {
        if (isSmallScreen && screen?.orientation) {
            screen.orientation.onchange = onOrientationChange;
        }
    }, [isSmallScreen, onOrientationChange]);

    return {
        isFullscreen,
        showFullscreenControls: showControls
    };
}
