import styles from './Chips.module.css';
import React, { ReactNode, SyntheticEvent } from 'react';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';
import cn from 'classnames';

export enum ChipsTheme {
    Rounded = 'rounded',
    Square = 'square'
}
interface ChipsProps {
    onClick?: (e: SyntheticEvent<HTMLSpanElement, MouseEvent>) => void;
    onDelete?: () => void;
    children: ReactNode | ReactNode[];
    color?: string;
    background?: string;
    className?: string;
    theme?: ChipsTheme;
    tooltipContent?: ReactNode;
}

function Chips({
    onDelete,
    children,
    onClick,
    color,
    background,
    className,
    theme = ChipsTheme.Rounded,
    tooltipContent
}: ChipsProps) {
    const props = {
        className: cn(styles.chips, className, styles[theme]),
        onClick: onClick,
        role: onClick ? 'button' : undefined,
        style: {
            color,
            background
        }
    };

    const content = (
        <>
            {children}
            {onDelete && (
                <button onClick={() => onDelete()} type="button">
                    delete
                </button>
            )}
        </>
    );

    return tooltipContent ? (
        <Tooltip content={tooltipContent} {...props} tagName="span">
            {content}
        </Tooltip>
    ) : (
        <span {...props}>{content}</span>
    );
}

export default Chips;
