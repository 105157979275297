import styles from './FormNoteControls.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AreaSelectionType } from '@he-novation/config/types/area-selection.types';
import { FileEnum } from '@he-novation/config/types/file.types';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { timeCodeToSeconds } from '@he-novation/lib-timecodes';
import { useAtomValue } from 'jotai';

import { audioAtom } from '$atoms/file-atoms/audio-atom';
import { videoAtom } from '$atoms/file-atoms/video-atom';
import { useAreaSelection } from '$hooks/useAreaSelection';
import { useAudioControls } from '$hooks/useAudioControls';
import { useTranslate } from '$hooks/useTranslate';
import { useVideoControls } from '$hooks/useVideoControls';
import { pageAndPageNumberSelector } from '$redux/content/file/fileSelectors';

type FormNoteControlsProps = {
    formId: string;
    fileType: FileEnum;
};

function shouldFixTimecodes(tcIn: string, tcOut: string) {
    const tcInSeconds = timeCodeToSeconds(tcIn, 25);
    const tcOutSeconds = timeCodeToSeconds(tcOut, 25);
    return tcInSeconds > tcOutSeconds;
}

function hasAreaSelectionNotes(fileType: FileEnum) {
    return ![FileEnum.Document, FileEnum.Office].includes(fileType);
}

export function FormNoteControls({ formId, fileType }: FormNoteControlsProps) {
    const { t } = useTranslate();

    const [type, setType] = useState(hasAreaSelectionNotes(fileType) ? 'areaselection' : 'global');
    const [shape, setShape] = useState<AreaSelectionType>(AreaSelectionType.Freehand);
    const videoState = useAtomValue(videoAtom);
    const audioState = useAtomValue(audioAtom);
    const videoControls = useVideoControls();
    const audioControls = useAudioControls();
    const { toggleAreaSelection, resetShapes, startShape } = useAreaSelection();
    const { page } = useSelector(pageAndPageNumberSelector);

    const currentTimecode =
        fileType === 'video' ? videoState.timecodeWithTimecodeStart : audioState.timecode;

    const [tcIn, setTcIn] = useState(currentTimecode);
    const [tcOut, setTcOut] = useState(currentTimecode);

    const audioTimecode = fileType === 'audio';

    useEffect(() => {
        return () => {
            toggleAreaSelection(false);
            resetShapes();
        };
    }, []);

    useEffect(() => {
        resetShapes();
        if (type !== 'areaselection') {
            toggleAreaSelection(false);
            return;
        }
        if (fileType === 'video') videoControls.pause();
        if (fileType === 'audio') audioControls.pause();
        startShape(shape);
    }, [type, shape]);

    useEffect(() => {
        if (type !== 'sequence') {
            setTcIn(currentTimecode);
            setTcOut(currentTimecode);
        }
    }, [currentTimecode, type]);

    const setTimecode = useCallback((truth: 'in' | 'out', tcIn: string, tcOut: string) => {
        const [setValue, setFixes] = truth === 'in' ? [setTcIn, setTcOut] : [setTcOut, setTcIn];
        const v = truth === 'in' ? tcIn : tcOut;
        if (shouldFixTimecodes(tcIn, tcOut)) {
            setFixes(v);
        }
        setValue(v);
    }, []);

    return (
        <div className={styles.controls}>
            <FormField
                formId={formId}
                name="type"
                className={formStyles.dark}
                type={'select'}
                value={type}
                onChange={(e) => setType(e.currentTarget.value)}
            >
                <option value="global">{t('player.Global')}</option>
                {hasAreaSelectionNotes(fileType) && (
                    <option value="areaselection">{t('player.Zone')}</option>
                )}
                {(fileType === 'video' || fileType === 'audio') && (
                    <option value="sequence">{t('player.Sequence')}</option>
                )}
            </FormField>

            {type === 'global' && fileType === FileEnum.PDF && page !== undefined && (
                <span className={styles.page}>
                    {t('common.Page')} {page + 1}
                </span>
            )}

            {type === 'areaselection' && (
                <>
                    {['audio', 'video'].includes(fileType) && (
                        <FormField
                            className={styles.timecode}
                            formId={formId}
                            name="tcIn"
                            audio={audioTimecode}
                            type={'timecode'}
                            value={tcIn}
                        />
                    )}
                    <FormField
                        id="note-shape"
                        name="shape"
                        className={styles.shapes}
                        innerClassName={styles.shapeTabs}
                        type="radio-toggle"
                        style={'bg5'}
                        value={shape}
                        onChange={(e) => setShape(e.currentTarget.value)}
                        options={[
                            {
                                label: (
                                    <Icon
                                        icon={{
                                            name: 'brush',
                                            stroke: 'black',
                                            fill: 'none'
                                        }}
                                    />
                                ),
                                value: 'freehand'
                            },
                            {
                                label: (
                                    <Icon
                                        icon={{
                                            name: 'square',
                                            stroke: 'black',
                                            fill: 'none'
                                        }}
                                    />
                                ),
                                value: 'rectangle'
                            }
                        ]}
                    />
                </>
            )}

            {type === 'sequence' && (
                <div className={styles.timecode}>
                    <Button
                        icon={[{ name: 'pin', stroke: 'none', fill: 'white' }]}
                        type="button"
                        onClick={() => setTimecode('in', currentTimecode, tcOut)}
                    />
                    <FormField
                        formId={formId}
                        name="tcIn"
                        type={'timecode'}
                        audio={audioTimecode}
                        value={tcIn}
                        onBlur={(e) => {
                            setTimecode('in', (e.currentTarget as HTMLInputElement).value, tcIn);
                        }}
                    />
                    <Icon
                        className={styles.chevron}
                        icon={[{ name: 'chevron', fill: 'none', stroke: 'white' }]}
                    />
                    <FormField
                        formId={formId}
                        name="tcOut"
                        type={'timecode'}
                        audio={audioTimecode}
                        value={tcOut}
                        onBlur={(e) => {
                            setTimecode('out', tcIn, (e.currentTarget as HTMLInputElement).value);
                        }}
                    />
                    <Button
                        icon={[{ name: 'pin', stroke: 'none', fill: 'white' }]}
                        type="button"
                        onClick={() => setTimecode('out', tcIn, currentTimecode)}
                    />
                </div>
            )}
        </div>
    );
}
