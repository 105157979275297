import { z, ZodOptional, type ZodNullable, type ZodString } from 'zod';
import sanitizeHTML from 'sanitize-html';

export const coercedBoolean = () =>
    z
        .union([z.boolean(), z.enum(['0', '1', 'true', 'false']).catch('false')])
        .optional()
        .transform((value) => {
            if (typeof value === 'boolean') return value;
            return value === 'true' || value === '1';
        });

type StringOrNull = string | null;
export function sanitizedString<N extends boolean = false, O extends boolean = false>({
    html,
    optional,
    min,
    max,
    nullable
}: { html?: boolean; optional?: O; min?: number; max?: number; nullable?: N } = {}) {
    let v = z.string();
    if (min) v = v.min(min);
    if (max) v = v.max(max);
    const v2 = optional ? z.string().optional() : z.string();

    const v3 = nullable ? v2.nullable() : v2;

    return v3.transform((value) => {
        return (
            (optional || nullable) && !value
                ? value
                : sanitizeHTML(
                      value,
                      html
                          ? undefined
                          : {
                                allowedTags: [],
                                allowedAttributes: {}
                            }
                  )
        ) as z.infer<
            O extends true
                ? N extends true
                    ? ZodNullable<ZodOptional<ZodString>>
                    : ZodOptional<ZodString>
                : N extends true
                ? ZodNullable<ZodString>
                : ZodString
        >;
    });
}
