import { z } from 'zod';
import { Preset } from './transcoding';
import { AssetStatus } from './db/enums';

export type PictureAsset = {
    uuid: string;
    url: string | null;
    urlExpires: Date | null;
    key: string | null;
};
export const ffprobeResultSchema = z.object({
    VideoStreamOffset: z.string().optional(),
    VideoCodec: z.string().optional(),
    VideoProfile: z.string().optional(),
    VideoBitRate: z.string().optional(),
    VideoSize: z.string().optional(),
    VideoDar: z.string().optional(),
    VideoSar: z.string().optional(),
    FrameRate: z.string().optional(),
    PixelFormat: z.string().optional(),
    ColorPrimaries: z.string().optional(),
    ColorRange: z.string().optional(),
    ColorSpace: z.string().optional(),
    ColorTransfer: z.string().optional(),
    AudioStreamOffset: z.string().optional(),
    AudioCodec: z.string().optional(),
    AudioBitRate: z.string().optional(),
    AudioSampleRate: z.string().optional(),
    AudioStreamCount: z.number().optional(),
    AudioStreams: z
        .array(
            z.object({
                StreamOffset: z.string().optional(),
                Codec: z.string().optional(),
                BitRate: z.string().optional(),
                SampleRate: z.string().optional(),
                Tags: z
                    .object({
                        creation_time: z.string().optional(),
                        language: z.string().optional(),
                        vendor_id: z.string().optional()
                    })
                    .optional()
            })
        )
        .optional(),
    Duration: z.string().optional(),
    BitRate: z.string().optional(),
    ImageSize: z.string().optional(),
    timecodestart: z.string().nullable().optional(),
    rotate: z.string().optional(),
    SubtitleStreamOffset: z.string().optional(),
    SubtitleCodec: z.string().optional(),
    SubtitleTimeBase: z.string().optional(),
    SubtitleRFrameRate: z.string().optional(),
    SubtitleAvgFrameRate: z.string().optional(),
    SubtitleCodecTimeBase: z.string().optional(),
    SubtitleDisposition: z.record(z.number()).optional()
});
export type FFProbeResult = z.infer<typeof ffprobeResultSchema>;

export const assetMetadataSchema = z.object({
    encryption: z.object({ algorithm: z.literal('aes-128'), key: z.string() }).optional(),
    playlist: z.array(z.string()).optional(),
    subtitleUuid: z.string().uuid().optional(),
    type: z.enum(['video', 'image']).optional(),
    bundle: z.array(z.string()).optional(),
    pages: z.array(z.string()).optional(),
    pageSizes: z
        .array(
            z.object({
                width: z.number().optional(),
                height: z.number().optional()
            })
        )
        .optional(),
    pagesSigned: z.array(z.string()).optional()
});

export type AssetMetadata = z.infer<typeof assetMetadataSchema>;

export const assetSchema = z.object({
    quality: z.string().nullable(),
    key: z.string().nullable(),
    mimeType: z.string().nullable(),
    size: z.number(),
    type: z.string().nullable(),
    url: z.string().nullable(),
    urlExpires: z.date().nullable(),
    uuid: z.string(),
    version: z.number(),
    collectionId: z.number().nullable().optional(),
    ffprobeResult: ffprobeResultSchema.optional(),
    exifResult: z.any().optional(),
    note: z.object({ uuid: z.string() }).optional(),
    comment: z.object({ uuid: z.string() }).optional(),
    subtitle: z.object({ uuid: z.string() }).optional(),
    name: z.string().optional().nullable(),
    expires: z.date().nullable().optional(),
    bucketName: z.string().optional().nullable(),
    metadata: assetMetadataSchema.optional(),
    status: z.enum(['PENDING', 'READY', 'DELETED', 'PURGED', 'ERROR']),
    created: z.date().nullable(),
    updated: z.date().optional()
});
export type Asset = z.infer<typeof assetSchema>;

export type AssetPreset = {
    name: string;
    description: string;
    preset: Omit<Preset, 'name'>;
    mimeTypes: string[];
};
export type AssetPresetWithClientId = AssetPreset & {
    clientId: number | null;
};

export type AssetCreatePayload = {
    uuid?: string;
    key?: string;
    fileUuid?: string;
    folderUuid?: string;
    noteUuid?: string;
    commentUuid?: string;
    taskUuid?: string;
    subtitleUuid?: string;
    version?: number;
    type: string;
    format?: string;
    quality?: string;
    page?: number | null;
    url?: string;
    urlExpires?: Date | null;
    size: number;
    mimeType?: string;
    name?: string | null;
    exifResult?: any;
    ffprobeResult?: any;
    userUuid?: string;
    status?: AssetStatus;
    bucketName: string;
    metadata?: AssetMetadata;
    ip?: string;
};
