import type { ObjectCannedACL } from '@aws-sdk/client-s3';
import type { AWSBucketInfos } from './aws.types';
import {
    TranscoderStatus,
    TranscoderTaskPresetStatus,
    TranscoderTaskStatus,
    WeekDay
} from './db/enums';
import type { Tags } from 'exiftool-vendored';
import { AssetMetadata, FFProbeResult } from './asset.types';

export type AudioMimeType =
    | 'audio/aac'
    | 'audio/aiff'
    | 'audio/amr'
    | 'audio/basic'
    | 'audio/flac'
    | 'audio/midi'
    | 'audio/mp3'
    | 'audio/mp4'
    | 'audio/mpeg'
    | 'audio/ogg'
    | 'audio/opus'
    | 'audio/vnd.dlna.adts'
    | 'audio/vnd.wave'
    | 'audio/wav'
    | 'audio/wave'
    | 'audio/webm'
    | 'audio/x-aiff'
    | 'audio/x-flac'
    | 'audio/x-hx-aac-adts'
    | 'audio/x-m4a'
    | 'audio/x-mp4a-latm'
    | 'audio/x-ms-wma'
    | 'audio/x-ogg'
    | 'audio/x-wav';

export const audioMimeTypes: AudioMimeType[] = [
    'audio/aac',
    'audio/aiff',
    'audio/amr',
    'audio/basic',
    'audio/flac',
    'audio/midi',
    'audio/mp3',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/opus',
    'audio/wav',
    'audio/wave',
    'audio/webm',
    'audio/x-aiff',
    'audio/x-flac',
    'audio/x-m4a',
    'audio/x-ms-wma',
    'audio/x-ogg',
    'audio/x-wav'
];

export type VideoMimeType =
    | 'application/mxf'
    | 'application/vnd.apple.mpegurl'
    | 'image/gif'
    | 'video/3gpp2'
    | 'video/3gpp'
    | 'video/avi'
    | 'video/m2ts'
    | 'video/mp4'
    | 'video/mpeg'
    | 'video/ogg'
    | 'video/quicktime'
    | 'video/webm'
    | 'video/x-flv'
    | 'video/x-m4v'
    | 'video/x-matroska'
    | 'video/x-ms-asf'
    | 'video/x-ms-wmv'
    | 'video/x-msvideo'
    | 'video/x-ogg';

export const videoMimeTypes: VideoMimeType[] = [
    'application/mxf',
    'image/gif',
    'video/3gpp',
    'video/avi',
    // 'video/m2ts', // FIXME detected as video/mp2t (which is the official mime-type for m2ts files)
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/quicktime',
    'video/webm',
    'video/x-flv',
    'video/x-m4v', // FIXME detected as video/mp4 by the browser, whether the server responds video/x-m4v ==> use alias?
    'video/x-matroska',
    'video/x-ms-asf',
    'video/x-ms-wmv',
    // 'video/x-msvideo',
    'video/x-ogg'
];

export type ImageMimeType =
    | 'application/postscript'
    | 'image/apng'
    | 'image/avif'
    | 'image/bmp'
    | 'image/dpx'
    | 'image/gif'
    | 'image/heic'
    | 'image/heif'
    | 'image/icns'
    | 'image/jp2'
    | 'image/jpeg'
    | 'image/png'
    | 'image/svg+xml'
    | 'image/targa'
    | 'image/tga'
    | 'image/tiff'
    | 'image/vnd.adobe.photoshop'
    | 'image/vnd.fpx'
    | 'image/vnd.microsoft.icon'
    | 'image/vnd.radiance'
    | 'image/webp'
    | 'image/x-adobe-dng'
    | 'image/x-canon-cr2'
    | 'image/x-canon-cr3'
    | 'image/x-canon-crw'
    | 'image/x-dpx'
    | 'image/x-epson-erf'
    | 'image/x-exr'
    | 'image/x-fujifilm-raf'
    | 'image/x-icns'
    | 'image/x-icon'
    | 'image/x-kodak-dcr'
    | 'image/x-kodak-k25'
    | 'image/x-kodak-kdc'
    | 'image/x-olympus-orf'
    | 'image/x-minolta-mrw'
    | 'image/x-mos'
    | 'image/x-nikon-nef'
    | 'image/x-panasonic-rw2'
    | 'image/x-panasonic-raw'
    | 'image/x-pentax-pef'
    | 'image/x-portable-pixmap'
    | 'image/x-raw'
    | 'image/x-sigma-x3f'
    | 'image/x-sony-arw'
    | 'image/x-sony-sr2'
    | 'image/x-sony-srf'
    | 'image/x-tga'
    | 'image/x-xcf';

export const imageMimeTypes: ImageMimeType[] = [
    'application/postscript',
    'image/apng',
    'image/avif',
    'image/bmp',
    // 'image/dpx', // mime type not detected
    'image/gif',
    'image/heic',
    'image/heif', // TODO OK but should be added in client_transcoding_preset_mime_types
    'image/icns',
    'image/jp2', // FIXME not supported by sharp but should
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/targa',
    'image/tga',
    'image/tiff',
    'image/vnd.adobe.photoshop',
    // 'image/vnd.fpx',
    'image/vnd.microsoft.icon',
    'image/vnd.radiance',
    'image/webp',
    'image/x-adobe-dng',
    'image/x-canon-cr2',
    //'image/x-canon-cr3',
    'image/x-canon-crw',
    'image/x-dpx',
    'image/x-epson-erf',
    'image/x-exr',
    'image/x-fujifilm-raf',
    'image/x-icns',
    'image/x-icon',
    'image/x-kodak-dcr',
    'image/x-kodak-k25',
    'image/x-kodak-kdc',
    'image/x-minolta-mrw',
    'image/x-mos',
    'image/x-nikon-nef',
    'image/x-olympus-orf',
    'image/x-panasonic-raw',
    'image/x-panasonic-rw2',
    'image/x-pentax-pef',
    'image/x-portable-pixmap',
    'image/x-raw',
    'image/x-sigma-x3f',
    'image/x-sony-arw',
    'image/x-sony-sr2',
    'image/x-sony-srf',
    'image/x-tga',
    'image/x-xcf'
];

export type SubtitlesMimeType =
    | 'application/subrip'
    | 'application/x-subrip'
    | 'model/stl'
    | 'text/srt'
    | 'text/vtt';

export const subtitlesMimeTypes: SubtitlesMimeType[] = [
    'application/subrip',
    'application/x-subrip',
    'model/stl',
    'text/srt',
    'text/vtt'
];

export type PdfMimeType = 'application/pdf';
export const pdfMimeTypes: PdfMimeType[] = ['application/pdf'];

export type BundleMimeType = 'application/zip';

export type AnyMimeType =
    | AudioMimeType
    | VideoMimeType
    | ImageMimeType
    | PdfMimeType
    | SubtitlesMimeType
    | BundleMimeType;

export type TranscodingTaskInput = {
    key: string;
    bucket: AWSBucketInfos;
    asset: {
        uuid: string;
        name: string;
        size: number;
    };
};

export type TranscodingTask = {
    uuid: string;
    transcoderUuid: string;
    input: TranscodingTaskInput;
    points: number;
    diskSize: number;
    details?: unknown;
    estimatedEndDate: Date | null;
    status: TranscoderTaskStatus;
    priority: TranscodingTaskPriority;
    created: Date | null;
} & (
    | { mimeType: BundleMimeType; presets: TaskPreset<ThumbnailPreset | ImagePreset>[] }
    | { mimeType: SubtitlesMimeType; presets: TaskPreset<ThumbnailPreset | SubtitlesPreset>[] }
    | { mimeType: AudioMimeType; presets: TaskPreset<ThumbnailPreset | AudioPreset>[] }
    | { mimeType: VideoMimeType; presets: TaskPreset<ThumbnailPreset | VideoPreset>[] }
    | { mimeType: ImageMimeType; presets: TaskPreset<ThumbnailPreset | ImagePreset>[] }
);

namespace Watermark {
    export type Position = {
        mode: 'absolute' | 'relative';
        x: number;
        y: number;
    };

    export type Alignment = {
        mode: 'align';
        x: 'left' | 'center' | 'right';
        y: 'top' | 'center' | 'bottom';
    };

    export type FixedSize = {
        mode: 'fixed';
    };

    export type RelativeOrAbsotluteSize = {
        mode: 'absolute' | 'relative';
        width: number;
        height?: number;
    };

    export type Size = FixedSize | RelativeOrAbsotluteSize;

    export type Offset = {
        mode: 'absolute' | 'relative';
        x: number;
        y: number;
    };

    export type CoverFit = {
        fit: 'cover';
    };

    export type NoFit = {
        fit: 'none';
        size: Watermark.Size;
        position: Watermark.Position | Watermark.Alignment;
        offset: Watermark.Offset;
    };
}
export type Watermark = {
    url?: string;
    key?: string;
    bucket?: AWSBucketInfos;
} & (Watermark.CoverFit | Watermark.NoFit);

export type Encryption = {
    algorithm: 'aes-128';
    apiKeyPath: string;
};

export type BurntSubtitles = {
    url?: string;
    key?: string;
    bucket?: AWSBucketInfos;
    subtitleUuid: string;
};

export type TaskPresetOutput = {
    bucketName: string;
    keyPrefix: string;
    bucket: AWSBucketInfos;
    ACL: ObjectCannedACL;
    watermark?: Watermark;
    burntSubtitles?: BurntSubtitles;
    encryption?: Encryption;
    assetUuid?: string;
    attachmentName?: string;
    frameRate?: number;
};

export type TaskPreset<P extends Preset> = {
    created: Date;
    updated: Date;
    status: TranscoderTaskPresetStatus;
    name: string;
    output: TaskPresetOutput;
    preset: P;
};

export type ImageFitOption = 'contain' | 'cover' | 'fill' | 'inside' | 'outside';
export type ImageOutputFormat = 'avif' | 'jpeg' | 'gif' | 'png' | 'tiff' | 'webp';
export type AudioOutputFormat =
    | 'mp3'
    | 'aac'
    | 'flac'
    | 'ogg'
    | 'opus'
    | 'wav'
    | 'm4a'
    | 'ac3'
    | 'eac3'
    | 'pcm';
export type ImagePlayableFormat = 'avif' | 'jpeg' | 'gif' | 'png' | 'webp';

export type ThumbnailPreset = {
    type: 'thumbnail';
    format: ImagePlayableFormat;
    maxWidth: number;
    maxHeight: number;
    fit?: ImageFitOption;
};

export type AudioPreset = {
    type: 'audio';
    codec:
        | 'auto'
        | 'aac'
        | 'mp3'
        | 'opus'
        | 'flac'
        | 'wav'
        | 'vorbis'
        | 'alac'
        | 'ac3'
        | 'eac3'
        | 'pcm';
    format: 'auto' | AudioOutputFormat;
    audioBitrate: 'auto' | number;
};
export type ImagePreset = {
    type: 'image';
    format: 'auto' | ImageOutputFormat;
    width?: number;
    height?: number;
    maxWidth?: number;
    maxHeight?: number;
    fit?: ImageFitOption;
};

export type PDFPreset = {
    type: 'pdf';
    format: 'auto';
    inputDensity?: number;
    outputQuality?: number;
};

export type VideoFormat = 'mp4' | 'webm' | 'ogg' | 'mov' | 'mkv' | 'avi' | 'hls' | 'mxf';
export type VideoCodec =
    | 'h264'
    | 'h265'
    | 'vp8'
    | 'vp9'
    | 'av1'
    | 'mpeg4'
    | 'theora'
    | 'xvid'
    | 'divx'
    | 'prores'
    | 'mpeg2'
    | 'raw'
    | 'jpeg2000';
export type VideoAudioCodec = 'aac' | 'mp3' | 'ac3' | 'opus' | 'vorbis' | 'alac' | 'flac' | 'pcm';

export type VideoPreset = {
    type: 'video';
    format: 'auto' | VideoFormat;
    videoCodec: 'auto' | VideoCodec;
    videoBitrate: 'auto' | number;
    resolution?: 'auto' | [number, number];
    fit: 'cover' | 'inside' | 'fill';
    audioCodec?: VideoAudioCodec | null;
    audioBitrate?: number;
    pixelFormat?: string;
};

export type BundlePreset = {
    type: 'bundle';
};

export type SubtitlesPreset = {
    type: 'subtitles';
};

export type Preset =
    | AudioPreset
    | BundlePreset
    | ImagePreset
    | PDFPreset
    | SubtitlesPreset
    | ThumbnailPreset
    | VideoPreset;

export enum TranscoderEventType {
    TranscoderReady = 'TranscoderReady',
    TranscoderRefusingJobs = 'TranscoderRefusingJobs',
    TranscoderShutdown = 'TranscoderShutdown',
    TaskStarted = 'TaskStarted',
    TaskProgress = 'TaskProgress',
    TaskFinished = 'TaskFinished',
    MetadataExifResult = 'MetadataExifResult',
    MetadataFFProbeResult = 'MetadataFFProbeResult',
    TaskPresetUpdate = 'TaskPresetUpdate',
    TaskPresetFinished = 'TaskPresetFinished',
    TaskFailed = 'TaskFailed'
}

export type TranscoderEventTranscoderReady = {
    type: TranscoderEventType.TranscoderReady;
    transcoderUuid: string;
    transcoderTag: string;
    transcodingPoints: number;
};

export type TranscoderEventTranscoderShutdown = {
    type: TranscoderEventType.TranscoderShutdown;
    transcoderUuid: string;
    transcoderTag: string;
};
export type TranscoderEventTranscoderRefusingJobs = {
    type: TranscoderEventType.TranscoderRefusingJobs;
    transcoderUuid: string;
};

export type TranscoderEventTaskStarted = {
    type: TranscoderEventType.TaskStarted;
    transcoderUuid: string;
    taskUuid: string;
};

export type TranscoderEventTaskProgress = {
    type: TranscoderEventType.TaskProgress;
    transcoderUuid: string;
    taskUuid: string;
    assetUuid: string;
    estimatedEndDate: string;
};

export type TranscoderEventTaskFinished = {
    type: TranscoderEventType.TaskFinished;
    transcoderUuid: string;
    taskUuid: string;
};

export type TranscoderEventMetadataExifResult = {
    type: TranscoderEventType.MetadataExifResult;
    transcoderUuid: string;
    taskUuid: string;
    assetUuid: string;
    exifResult: Record<string, any>;
    sourceAssetType?: string;
};

export type TranscoderEventMetadataFFProbeResult = {
    type: TranscoderEventType.MetadataFFProbeResult;
    transcoderUuid: string;
    taskUuid: string;
    assetUuid: string;
    ffprobeResult: Record<string, any>;
    sourceAssetType?: string;
};

export type TrancoderEventTaskPresetRunning = {
    status: typeof TranscoderTaskPresetStatus.RUNNING;
    createAsset: boolean;
    asset?: {
        uuid: string;
        name: string;
        bucket: AWSBucketInfos;
        key?: string;
        format: string;
        type?: 'player' | 'thumbnail' | 'preview' | 'other' | 'subtitle';
    };
};

export type TranscoderEventTaskPresetSkipped = {
    status: typeof TranscoderTaskPresetStatus.SKIPPED;
};

export type TranscoderEventTaskPresetError = {
    status: typeof TranscoderTaskPresetStatus.ERROR;
    error: string;
    asset?: {
        uuid: string;
    };
};

export type TranscoderEventTaskPresetUpdate = {
    type: TranscoderEventType.TaskPresetUpdate;
    transcoderUuid: string;
    transcoderTag: string;
    taskUuid: string;
    presetName: string;
    status: TranscoderTaskPresetStatus;
    sourceAssetUuid: string;
} & (
    | TrancoderEventTaskPresetRunning
    | TranscoderEventTaskPresetError
    | TranscoderEventTaskPresetSkipped
);

export type TranscoderEventTaskPresetFinishedOutput = {
    quality?: string;
    key?: string;
    mimeType?: string;
    size?: number;
    metadata?: AssetMetadata;
    ffprobeResult?: FFProbeResult;
    type: 'player' | 'thumbnail' | 'preview' | 'other' | 'subtitle' | 'export';
    format?: string;
    subtitleEntries?: {
        timecodeIn: string;
        timecodeOut: string;
        content: string;
        metadata: unknown;
    }[];
};

export type TranscoderEventTaskPresetFinished = {
    type: TranscoderEventType.TaskPresetFinished;
    transcoderUuid: string;
    taskUuid: string;
    presetName: string;
    assetUuid: string;
    sourceAssetUuid: string;
    output: TranscoderEventTaskPresetFinishedOutput;
};

export type TranscoderEventTaskFailed = {
    type: TranscoderEventType.TaskFailed;
    transcoderUuid: string;
    transcoderTag: string;
    taskUuid: string;
    error?: string;
};

export type TranscodingEvent =
    | TranscoderEventTranscoderReady
    | TranscoderEventTranscoderRefusingJobs
    | TranscoderEventTranscoderShutdown
    | TranscoderEventTaskStarted
    | TranscoderEventTaskProgress
    | TranscoderEventTaskFinished
    | TranscoderEventMetadataExifResult
    | TranscoderEventMetadataFFProbeResult
    | TranscoderEventTaskPresetUpdate
    | TranscoderEventTaskPresetFinished
    | TranscoderEventTaskFailed;

export type Transcoder = {
    uuid: string;
    instanceClass: string;
    transcodingPoints: number;
    status: TranscoderStatus;
    diskSize: number;
    tag: string;
    expiry: Date;
    tasks: Omit<TranscodingTask, 'transcoderUuid' | 'presets'>[];
};

export type ExifResultWithPaths = Omit<Tags, 'FileSize'> & { FileSize?: number; ViewBox?: string };
export type ExifResult = Omit<ExifResultWithPaths, 'SourceFile' | 'Directory'>;

export type TranscoderInstanceClass = {
    instanceClass: string;
    memory: number;
    vCpus: number;
    diskSize: number;
    points: number;
};

export type TranscodingScheduleEntry = {
    startDay: WeekDay;
    startHour: number;
    duration: number;
    transcodingPoints: number;
};

export type TranscodingScheduleMappedEntry = {
    startDay: number;
    startHour: number;
    duration: number;
    points: number;
};

export type PIDData = {
    P: number;
    I: number;
    D: number;
    INTEGRAL: number[];
    PREVIOUS_ERROR: number | undefined;
    POINTS_CAPACITY: number[];
    POINTS_LOAD: number[];
    TIMESTAMPS: number[];
    reset: () => void;
};

export enum TranscodingTaskPriority {
    HIGH = 10,
    MEDIUM = 5,
    LOW = 0
}
