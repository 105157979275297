import { atom } from 'jotai';

export const workspaceAtom = atom<{ name: string; uuid: string } | null>(null);

export const workspaceNameAtom = atom((get) => {
    const workspace = get(workspaceAtom);
    if (!workspace) {
        throw new Error('workspaceAtom is null');
    }
    return workspace.name;
});
