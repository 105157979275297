import { assetPaths, pathWithParams } from '@he-novation/config/paths/herawApiPaths';
import type { Asset } from '@he-novation/config/types/asset.types';
import { Bucket } from '@he-novation/config/types/bucket.types';
import {
    AssetStatusBody,
    AssetUploadAbortBody,
    AssetUploadCommandParam,
    AssetUploadCompleteBody,
    AssetUuidSchema,
    MigrateFolderAssetsBody
} from '@he-novation/config/types/payloads/asset.payload';
import { apiFetch } from './apiFetch';

export const asyncAssetFetch = (assetUuid: string) =>
    apiFetch(pathWithParams(assetPaths.single), {
        params: { assetUuid },
        method: 'GET'
    });

export const asyncCompleteMultipartUpload = (
    assetUuid: string,
    body: AssetUploadCompleteBody
): Promise<Asset> =>
    apiFetch(assetPaths.upload, {
        method: 'PATCH',
        params: { assetUuid } satisfies AssetUploadCommandParam,
        body
    });
export const asyncAbortMultipartUpload = (assetUuid: string, body: AssetUploadAbortBody) =>
    apiFetch(assetPaths.upload, {
        method: 'DELETE',
        params: { assetUuid } satisfies AssetUploadCommandParam,
        body
    });

export const asyncAssetStatusUpdate = (assetUuid: string, body: AssetStatusBody) =>
    apiFetch(assetPaths.status, {
        method: 'PUT',
        params: { assetUuid } satisfies AssetUuidSchema,
        body
    });

export async function getSignedUrl(assetUuid: string): Promise<string> {
    const { url } = await apiFetch(assetPaths.sign, {
        params: { assetUuid },
        method: 'GET'
    });
    return url;
}

export async function asyncBucketsFetch(): Promise<Bucket[]> {
    return apiFetch(assetPaths.buckets);
}

export async function asyncMigrateFolderAssets(body: MigrateFolderAssetsBody) {
    return apiFetch(assetPaths.migrateFolder, {
        method: 'PUT',
        body
    });
}
