import styles from './Error.module.css';
import React, { ReactNode } from 'react';
import cn from 'classnames';

type ErrorProps = {
    error?: string | ReactNode;
    style?: React.CSSProperties;
};

export function Error({ error, style }: ErrorProps) {
    const props =
        typeof error === 'string'
            ? { dangerouslySetInnerHTML: { __html: error } }
            : { children: error };
    return <div className={cn(styles.error, 'c-error')} style={style} {...props} />;
}
